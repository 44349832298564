import { Table } from 'antd';
import { applicationUrl, capitalize, capitalizeFirstLetter, formatMoney, getRandomColor } from '../../../utils';
import moment from 'moment';
import useFetch from '../../../custom_hooks/useFetch';
import TableLoading from '../../../components/TableLoading';
import { statusColor } from '../../../data';

export default function WithdrawalStatementTable() {
    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/withdrawals`);

    if (isLoading) return <TableLoading />;

	const columns = [
		{
			title: 'Date',
			dataIndex: 'withDrawCreatedDate',
			render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
		},
		{
			title: 'Type',
			dataIndex: 'withDrawType',
		},
		{
			title: 'Source account',
			dataIndex: 'withDrawCwCollection',
		},
		{
			title: 'Recipient',
			render: (item) => (
				<span>
					{item?.withDrawType === 'BANK' ? (
						<>
							<div className="w-full flex items-center gap-[.5rem]">
								<img
									src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item?.withDrawBankName)}&background=${getRandomColor()}&color=fff`}
									className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
									alt="avatar"
								/>
								<span>{item?.withDrawBankName}</span>
							</div>
						</>
					) : (
						' - '
					)}
				</span>
			),
		},
		{
			title: 'Recipient account',
			render: (item) => <span>{item?.withDrawType === 'BANK' ? <>{item?.withDrawAccountNumber}</> : item?.withDrawMpesaNumber}</span>,
		},
		{
			title: 'Status',
			dataIndex: 'withDrawStatus',
			render: (item) => {
				const roleColor = statusColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6'
	
				return (
					<div
						style={{
							background: roleColor,
						}}
						className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
							<circle cx="2.66602" cy="2" r="2" fill="#212121" />
						</svg>
						<span>{capitalize(item?.split('_')?.join(' '))}</span>
					</div>
				);
			},
		},
		{
			title: 'Reason',
			dataIndex: 'withDrawParticular',
			render: (item) => <span>{capitalizeFirstLetter(item)}</span>,
		},
		{
			title: 'Amount',
			dataIndex: 'withDrawAmount',
			render: (item) => <span>{formatMoney(item, 'KES')}</span>,
		},
	];

    return (
        <>
            <Table
                rowKey="withDrawId"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 1200,
                }}
            />
        </>
    );
}
