import radio from '../../../assets/svg/radio.svg';
import radioActive from '../../../assets/svg/radioActive.svg';

export default function OptionsCard({
	icon,
	header,
	content,
	cardKey,
	activeOption,
    handleActiveOption,
	disabled
}) {
    

	return (
		<>
			<button
				disabled={disabled}
				onClick={() => handleActiveOption(cardKey)}
				style={{
					background: 'white',
					border: cardKey === activeOption ? '1px solid var(--Ndovu-Black, #121212)' : '1px solid var(--Tile-stroke, #E2E9EE)',
				}}
				className='w-[12.25rem] max-w-full h-[13.625rem] p-[1rem] rounded-[0.75rem] flex flex-col justify-center items-center'>
				<div className='flex justify-center items-center w-full'>
					<div className='flex justify-center w-full'>
						<img src={icon} alt='icon' />
					</div>

					<div className='flex justify-end'>
						<>
							{cardKey === activeOption ? (
								<img src={radioActive} alt='radio' />
							) : (
								<img src={radio} alt='radioActive' />
							)}
						</>
					</div>
				</div>
				<span className='label_2 text-center mt-[2.5rem]'>{header}</span>
				<span className='paragraph_3 text-center mt-[1.25rem]'>{content}</span>
			</button>
		</>
	);
}
