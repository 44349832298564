import React from 'react';
import { Breadcrumb } from 'antd';

export default function BreadCrumb({ breadList, header, info }) {
	return (
		<>
			<div className='w-full flex flex-col gap-[.75rem] bg-white'>
				<Breadcrumb separator='/' items={breadList} />
				<span className='heading_1'>
					{header}
				</span>
				<span className='paragraph_1'>{info}</span>
			</div>
		</>
	);
}
