import moment from 'moment';
import TableLoading from '../../../components/TableLoading';
import useFetch from '../../../custom_hooks/useFetch';
import { applicationUrl, capitalize, formatMoney, getRandomColor } from '../../../utils';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { debitNoteTypeColor } from '../../../data';
import { useEffect } from 'react';

export default function ContributionsTypeStatementTable() {
    const { activeContributionType } = useSelector((state) => state.obj);

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/sub-contribution/recent?walletCode=${activeContributionType?.rdnCwCode}`);

    useEffect(() => {}, [data]);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
            sorter: (a, b) => a - b,
        },
        {
            title: 'Member name',
            dataIndex: 'gpmName',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Activity',
            dataIndex: 'trxSource',
            render: (item) => {
                const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item)}</span>
                    </div>
                );
            },
        },
        {
            title: 'Debit (KES)',
            render: (item) => {
                const { trxType, trxAmount } = item;
                if (trxType === 'DEBIT') {
                    return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
                }
                return <span>-</span>;
            },
        },
        {
            title: 'Credit (KES)',
            render: (item) => {
                const { trxType, trxAmount } = item;
                if (trxType === 'CREDIT') {
                    return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
                }
                return <span>-</span>;
            },
        },
    ];

    return (
        <>
            <Table
                rowKey="trxRef"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                dataSource={data?.content}
                scroll={{
                    x: 1200,
                }}
            />
        </>
    );
}
