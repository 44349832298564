import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleActiveTab } from '../../../features/obj/objSlice';
import { formatMoney } from '../../../utils';

export default function AvailableBalanceCard({ hasFooter, header, amount, link, background, footer }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function handleNavigate() {
        switch (link) {
            case '/members/balance':
                if (header === 'Opening members balance') {
                    await dispatch(
                        handleActiveTab({
                            memberBalance: '2',
                        })
                    );
                } else if (header === 'Total members balance') {
                    await dispatch(
                        handleActiveTab({
                            memberBalance: '1',
                        })
                    );
                }
                await navigate(link);
                break;

            case '/group-activity/statements':
                if (header === 'Chama collection') {
                    await dispatch(
                        handleActiveTab({
                            statements: '2',
                        })
                    );
                } else if (header === 'Withdrawals') {
                    await dispatch(
                        handleActiveTab({
                            statements: '3',
                        })
                    );
                }
                await navigate(link);
                break;

            case '/group-activity/statements/contributions':
                await navigate(link);
                break;

            default:
                break;
        }
    }

    return (
        <>
            <button
                onClick={handleNavigate}
                style={{
                    background: background,
                    border: '1px solid #E2E9EE',
                    padding: '1.75rem 0.8125rem 1.75rem 1.5rem',
                }}
                className="w-full flex flex-col flex-shrink-0 justify-center items-start h-[7rem] rounded-[1rem]"
            >
                <div className="w-full flex items-center gap-[1rem]">
                    <span className="activity_card_header col-span-2 text-[#121212] text-center">{header}</span>
                </div>
                <span className="activity_card_text !text-[#121212]">{formatMoney(amount, 'KES')}</span>
                {hasFooter ? <span className="regular_12">{footer}</span> : null}
            </button>
        </>
    );
}
