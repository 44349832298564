import { Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import TableLoading from '../../../components/TableLoading';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchDeclinedInvites,
	fetchPendingInvites,
} from '../../../features/fetch/fetchSlice';
import { applicationUrl, customToast, getRandomColor } from '../../../utils';
import { save } from '../../../features/save/saveSlice';

export default function MembersRejectedInviteTable() {
	const { isLoading } = useFetchDispatch(fetchDeclinedInvites);

	const { invitesRejected } = useSelector((state) => state.fetch);

	const { saving } = useSelector((state) => state.save);

	const dispatch = useDispatch();

	async function handleResend(item) {
		let data = {};
		data.mbiInviteLink = item?.mbiInviteLink;
		data.mbiStatus = 'PENDING';
		data.url = applicationUrl().url;
		data.saveUrl = '/api/v1/reg/inviteAction';

		const res = await dispatch(save(data));

		if (res?.payload?.success) {
			await dispatch(fetchPendingInvites());
			await dispatch(fetchDeclinedInvites());
			await customToast({
				content: res?.payload?.messages?.message ?? 'Decline has been undone.',
				bdColor: 'success',
			});
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}

	useEffect(() => {}, [invitesRejected]);

	if (isLoading) return <TableLoading />;

	const columns = [
		{
			title: 'Date',
			dataIndex: 'mbiInviteDate',
			render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
		},
		{
			title: 'Name',
			dataIndex: 'mbiName',
			render: (item) => (
				<>
					<div className='w-full flex items-center gap-[.5rem]'>
						<img
							src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
							className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
							alt='avatar'
						/>
						<span>{item}</span>
					</div>
				</>
			),
		},
		{
			title: 'National ID',
			dataIndex: 'mbiNationalId',
		},
		{
			title: 'Phone',
			dataIndex: 'mbiPhone',
		},
		{
			title: 'Email',
			dataIndex: 'mbiEmail',
		},
		{
			title: 'Action',
			render: (item) => (
				<div className='flex items-center gap-[.75rem]'>
					<button
						disabled={saving}
						onClick={() => handleResend(item)}
						className='modal_btn_dark'
						type='button'>
						Undo
					</button>
				</div>
			),
		},
	];

	return (
		<div>
			<Table
				rowKey='mbiId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={invitesRejected}
				scroll={{
                    x: 1200,
                }}
			/>
		</div>
	);
}
