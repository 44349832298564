import PendingInviteCard from './cards/PendingInviteCard';

export default function PendingChamaList({ pendingInvites }) {
	return (
		<>
			<div className='flex flex-col w-full bg-[#FDFFE9] rounded-[1.25rem] h-full p-[2.5rem]'>
				<span className='heading_4'>Pending approval</span>
				<div className='flex items-center overflow-x-auto overflow-y-hidden h-auto w-full mt-[1.25rem] gap-[.44rem]'>
					{pendingInvites?.map((item, index) => {
						return (
							<div className='h-full' key={index}>
								<PendingInviteCard item={item} />
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}
