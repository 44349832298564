import { useDispatch, useSelector } from 'react-redux';
import { Form, Steps } from 'antd';
import { useRef, useState } from 'react';
import { customToast } from '../../utils';
import { handleAuthData, logout, register } from '../../features/auth/authSlice';
import PersonalDetailsStep from './registration_steps/PersonalDetailsStep';
import PasswordStep from './registration_steps/PasswordStep';
import VerifyIdStep from './registration_steps/VerifyIdStep';
import LocationStep from './registration_steps/LocationStep';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';

const chamaIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="63" viewBox="0 0 57 63" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.0696 18.5156C22.9288 15.8416 22.1907 13.1607 20.802 10.6827C20.0654 9.36866 19.1894 8.18978 18.2043 7.15393C17.0419 7.6533 15.9132 8.24274 14.8291 8.91928C15.9526 9.91554 16.9353 11.1197 17.718 12.5168C18.4455 13.8144 18.9472 15.1824 19.236 16.5726C19.58 16.8265 19.9399 17.0655 20.3138 17.2882C21.2032 17.8168 22.1277 18.2241 23.0696 18.5156Z"
            fill="#DB2A12"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6622 23.5697C21.4773 23.2742 20.3067 22.8525 19.168 22.2979C18.6596 24.9016 17.4627 27.3536 15.6578 29.3386C16.7381 29.9722 17.7504 30.7156 18.6794 31.5555C20.652 29.255 22.0045 26.4983 22.6622 23.5697Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2412 34.6019C14.0853 33.658 12.778 32.9085 11.363 32.4008C8.48271 33.7053 5.36017 33.9168 2.46851 33.1674C2.56334 34.5322 2.75549 35.8861 3.04247 37.2167C6.90778 37.8653 11.003 37.1787 14.6573 34.9704C14.8549 34.8508 15.0495 34.7282 15.2412 34.6019Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8264 42.0751C17.5428 43.5453 15.5661 45.5536 14.1102 48.0434C13.3379 49.3644 12.7576 50.7426 12.3607 52.1467C13.2473 52.8121 14.1863 53.4299 15.1762 53.9944C15.3459 54.0909 15.5167 54.1858 15.6875 54.2784C15.9766 52.7723 16.5232 51.2887 17.3433 49.8867C18.106 48.5826 19.0491 47.4451 20.1231 46.4882C20.1664 46.052 20.1891 45.6098 20.1891 45.1627C20.1886 44.0998 20.0631 43.0663 19.8264 42.0751Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.3118 39.9418C24.6401 41.1693 24.8601 42.4483 24.9594 43.7648C27.2712 42.9055 29.7888 42.7498 32.2244 43.3831C32.2145 42.0791 32.3257 40.7813 32.5522 39.5106C29.7869 38.8841 26.9528 39.0539 24.3118 39.9418Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.2064 41.2384C37.007 42.6942 37.0402 44.1884 37.3224 45.6606C39.7964 47.5382 41.5007 50.1496 42.3113 53.0209C43.3655 52.293 44.3719 51.4809 45.3199 50.5867C43.9499 46.9163 41.3818 43.686 37.7922 41.569C37.5982 41.4545 37.4028 41.3445 37.2064 41.2384Z"
            fill="#DB2A12"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.1976 33.3742C43.548 34.6352 46.2068 35.3459 49.0224 35.3459C50.5177 35.3459 51.9681 35.1454 53.3523 34.7687C53.5154 33.4185 53.5807 32.0642 53.5515 30.717C52.1337 31.2417 50.6097 31.5266 49.0224 31.5266C47.5481 31.5266 46.1279 31.2808 44.7968 30.8247C44.4057 31.0123 44.02 31.2216 43.6406 31.4535C42.7396 32.0039 41.9238 32.6506 41.1976 33.3742Z"
            fill="#DB2A12"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.1928 30.298C38.0224 29.4159 38.9496 28.608 39.9707 27.8917C38.068 26.1962 36.6318 23.9784 35.8806 21.4612C34.8231 22.0899 33.7078 22.6052 32.5544 23.0032C33.4829 25.818 35.0956 28.3169 37.1928 30.298Z"
            fill="#DB2A12"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.7378 18.0993C33.0661 17.5508 34.3119 16.7786 35.4144 15.799C35.7669 12.6914 37.1224 9.89562 39.1416 7.75524C37.9793 7.17857 36.7923 6.6999 35.5902 6.31677C33.1734 9.34617 31.7252 13.2101 31.7252 17.4196C31.7252 17.6468 31.729 17.8735 31.7378 18.0993Z"
            fill="#DB2A12"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.419 17.5759C16.5627 15.6718 14.6107 12.8392 13.7245 9.6959C12.6685 10.4262 11.6602 11.2408 10.7106 12.1379C12.0826 15.8202 14.6556 19.0609 18.2516 21.1848C18.6319 21.4095 19.0172 21.6178 19.4063 21.8096C20.5307 22.3646 21.6889 22.7841 22.8626 23.0744C26.1153 23.8785 29.4848 23.6852 32.569 22.6048C33.7252 22.1997 34.8418 21.6698 35.8969 21.0209C38.3382 19.5199 40.4518 17.3826 41.9766 14.6808C42.7251 13.3542 43.2881 11.9701 43.6723 10.56C42.813 9.8917 41.9029 9.27133 40.9435 8.7044C40.7789 8.607 40.6139 8.5121 40.4479 8.41919C40.1672 9.93116 39.6379 11.4216 38.843 12.8297C37.9544 14.4041 36.8129 15.7368 35.5014 16.8042C34.4073 17.6948 33.1945 18.4011 31.9114 18.9101C29.1679 19.9975 26.1026 20.1813 23.1858 19.3331C22.1166 19.0225 21.0673 18.5729 20.0606 17.9785C19.8423 17.8496 19.6285 17.7153 19.419 17.5759Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.0019 25.4727C49.2141 24.8476 45.201 25.5092 41.6199 27.6373C41.2409 27.8626 40.873 28.1005 40.5173 28.3499C39.4888 29.0706 38.5575 29.889 37.7284 30.786C35.4294 33.2726 33.9133 36.3623 33.2881 39.6443C33.0535 40.8748 32.9445 42.1324 32.966 43.395C33.0161 46.3159 33.7654 49.2653 35.2849 51.9743C36.0308 53.304 36.9178 54.497 37.9152 55.5452C39.0922 55.0399 40.2351 54.4434 41.3328 53.7588C40.1952 52.7511 39.2007 51.5321 38.4076 50.1182C37.5221 48.5391 36.9664 46.8573 36.7198 45.1574C36.5134 43.7386 36.5217 42.3072 36.7324 40.9124C37.182 37.9299 38.5541 35.1152 40.7232 32.9486C41.5182 32.1548 42.4203 31.4476 43.4231 30.8516C43.641 30.7224 43.861 30.5997 44.0828 30.4835C47.1112 28.9014 50.4723 28.5868 53.5644 29.3747C53.471 28.0595 53.2827 26.7548 53.0019 25.4727Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4324 56.3848C22.8538 53.3212 24.3057 49.4136 24.3057 45.1567C24.3057 44.7066 24.2891 44.2596 24.2575 43.8176C24.1655 42.5416 23.9422 41.3027 23.6026 40.1153C22.6602 36.8244 20.8187 33.9295 18.3705 31.732C17.4524 30.908 16.4491 30.1822 15.3763 29.5705C12.894 28.1553 10.0381 27.3494 6.99921 27.3494C5.50555 27.3494 4.05666 27.5441 2.67442 27.91C2.51143 29.2215 2.44575 30.5371 2.47543 31.8456C3.89172 31.336 5.41408 31.0592 6.99921 31.0592C8.77018 31.0592 10.4628 31.4051 12.0168 32.0343C13.3134 32.5594 14.5137 33.2823 15.5821 34.1668C17.8664 36.0575 19.5488 38.6871 20.2869 41.7021C20.5574 42.8074 20.7009 43.9643 20.7009 45.1562C20.7009 45.415 20.6941 45.6723 20.681 45.9276C20.4981 49.4161 19.0822 52.5673 16.8744 54.9286C18.0377 55.5132 19.2272 55.9973 20.4324 56.3848Z"
            fill="#DB2A12"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.4577 0.761869C32.0191 2.33019 32.8965 5.80413 31.4178 8.52067C29.9391 11.2377 26.6636 12.1683 24.1022 10.5995C21.5408 9.03115 20.6629 5.55722 22.1421 2.84068C23.6209 0.124138 26.8964 -0.806452 29.4577 0.761869Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.799 17.4676C54.799 20.6052 52.3088 23.1484 49.2367 23.1484C46.1645 23.1484 43.6744 20.6052 43.6744 17.4676C43.6744 14.33 46.1645 11.7867 49.2367 11.7867C52.3088 11.7867 54.799 14.33 54.799 17.4676Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.2577 48.0521C50.5974 49.6204 47.1965 48.6898 45.6604 45.9732C44.1249 43.2566 45.036 39.7825 47.6963 38.2142C50.3561 36.6458 53.7575 37.5764 55.2931 40.293C56.8287 43.0097 55.9175 46.4832 53.2577 48.0521Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.5791 61.9412C24.0173 60.3729 23.1399 56.899 24.619 54.1824C26.0978 51.4654 29.3732 50.5348 31.9346 52.1036C34.4959 53.672 35.3733 57.1459 33.8946 59.8624C32.4159 62.579 29.1404 63.5096 26.5791 61.9412Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.23816 45.2355C1.23816 42.0979 3.72831 39.5547 6.80045 39.5547C9.8726 39.5547 12.3627 42.0979 12.3627 45.2355C12.3627 48.3731 9.8726 50.9164 6.80045 50.9164C3.72831 50.9164 1.23816 48.3731 1.23816 45.2355Z"
            fill="#FFB600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.78131 14.6511C5.44156 13.0827 8.8424 14.0133 10.3784 16.7299C11.9145 19.4465 11.0028 22.9206 8.34258 24.4889C5.68282 26.0573 2.28149 25.1267 0.745946 22.4101C-0.789597 19.6935 0.121544 16.2199 2.78131 14.6511Z"
            fill="#FFB600"
        />
    </svg>
);

export default function Register() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authData } = useSelector((state) => state.auth);

    const formRef = useRef(null);

    const [current, setcurrent] = useState(0);

    const onFinish = async (data) => {
        data.usrId = authData?.usrId;

        const res = await dispatch(register(data));

        if (res?.payload?.success) {
            await customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'success',
                id: 9989877626626,
            });
            await dispatch(logout());
            await navigate('/login');
        } else {
            await customToast({
                content: res?.payload?.message,
                bdColor: 'error',
                id: 72676378292,
            });
        }
    };

    function handleNext() {
        setcurrent(current + 1);
    }

    function handleBack() {
        setcurrent(current - 1);
    }

    const steps = [
        {
            key: 0,
            title: 'Personal Details',
            content: <PersonalDetailsStep handleNext={handleNext} form={form} />,
        },
        {
            key: 1,
            title: 'Verify ID',
            content: <VerifyIdStep handleNext={handleNext} handleBack={handleBack} form={form} />,
        },
        {
            key: 2,
            title: 'Location',
            content: <LocationStep handleNext={handleNext} handleBack={handleBack} form={form} />,
        },
        {
            key: 3,
            title: 'Password Setup',
            content: <PasswordStep handleBack={handleBack} form={form} />,
        },
    ];

    const items = steps.map((item) => ({
        key: item.key,
        title: item.title,
        icon: item.icon,
    }));

    useEffect(() => {
        form.setFieldsValue({
            ...authData,
            usrDob: authData?.usrDob ? dayjs(authData?.usrDob) : null,
        });
    }, [authData, form]);

    return (
        <>
            <div className="flex w-full justify-center pt-[3.69rem] register">
                <div className="w-full lg:w-[50rem] xl:w-[60rem] flex flex-col h-auto">
                    <Link to="/" className="flex items-center w-full gap-[1.06rem] px-[2.31rem] lg:p-0">
                        {chamaIcon}
                        <span className="heading_4">Chamify Africa.</span>
                    </Link>

                    <span className="heading_1 mt-[1.75rem] px-[2.31rem] lg:p-0">Sign up</span>
                    <span className="paragraph_1 mt-[.75rem] px-[2.31rem] lg:p-0">Let’s create an account.</span>

                    <div className="w-full h-full mt-0 lg:mt-[2.44rem]">
                        <Form
                            onValuesChange={(data) => {
                                dispatch(handleAuthData(data));
                            }}
                            layout="vertical"
                            ref={formRef}
                            name="registration"
                            onFinish={onFinish}
                            scrollToFirstError
                            form={form}
                            initialValues={{
                                ...authData,
                                usrDob: authData?.usrDob ? dayjs(authData?.usrDob) : null,
                            }}
                            className=""
                        >
                            <div className="hidden lg:flex">
                                <Steps className="register_step" current={current} labelPlacement="vertical" items={items} />
                            </div>

                            <div className="mt-[2.12rem] p-[2.31rem]">{steps[current].content}</div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}
