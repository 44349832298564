import { Image, message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { useState } from 'react';
import { customToast } from '../utils';
import imgPlaceholder from '../assets/img/imgPlaceholder.jpeg';

const url = process.env.REACT_APP_API_BASE_URL;

export default function FileUploadDragger({ name, onSuccess, fileName }) {
    const [fileUploaded, setfileUploaded] = useState('');

    const beforeUpload = (file) => {
        const isLt20M = file.size / 1024 / 1024 < 20;

        if (!isLt20M) {
            customToast({
                content: `File must be smaller than 20MBs`,
                bdColor: '',
                id: 72782762691,
            });
        }
        return isLt20M;
    };

    const props = {
        name: 'file',
        showUploadList: false,
        beforeUpload: beforeUpload,
        multiple: false,
        accept: 'image/png, image/jpeg, image/jpg',
        action: `${url}/v1/api/att`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                if (info?.file?.response?.success) {
                    const data = { ...info?.file?.response, fileName: name };
                    onSuccess(data);
                    setfileUploaded(data?.data?.result);
                    message.success(`${info.file.name} file uploaded.`);
                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    return (
        <>
            <Dragger className="drag_n_drop" {...props}>
                {fileUploaded?.length ? (
                    <>
                        <div className="w-full h-full flex justify-evenly items-center">
                            {/* <img className='py-[.88rem] px-[1rem] w-full h-full' src={url + fileUploaded} alt="ID card" /> */}
                            <Image width={'23.25rem'} src={imgPlaceholder} />
                            <div className="w-full flex justify-end mt-[1.5rem]">
                                <button type="button" className="cstm-btn !w-[10.625rem]">
                                    Edit
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className="ant-upload-drag-icon flex w-full justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path
                                    d="M4.5551 16.8261C2.60745 16.8261 1.02856 15.4978 1.02856 13.5501C1.02856 11.6025 2.60745 10.0236 4.5551 10.0236C4.68754 10.0236 4.81828 10.0309 4.94693 10.0451V10.0236H4.99544C4.96342 9.76685 4.94693 9.50531 4.94693 9.23991C4.94693 5.77742 7.75383 2.97052 11.2163 2.97052C13.561 2.97052 15.6051 4.25766 16.6805 6.16389C16.9433 6.12524 17.2122 6.10521 17.4857 6.10521C20.5154 6.10521 22.9714 8.56125 22.9714 11.5909C22.9714 14.0849 21.3071 15.9861 19.0286 16.6128M11.8269 21.5705V12.5705M11.8269 12.5705L8.22856 16.2822M11.8269 12.5705L15.4286 16.2822"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </p>
                        <p className="ant-upload-text">Drag & Drop an image here to upload</p>
                        <p className="ant-upload-hint">.jpg, jpeg or .png</p>

                        <div className="w-full flex justify-center mt-[1.5rem]">
                            <button type="button" className="cstm-btn !w-[10.625rem]">
                                Choose file
                            </button>
                        </div>
                    </>
                )}
            </Dragger>
        </>
    );
}
