import { Spin, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { capitalize, formatMoney } from '../../../utils';
import STKPushSentModal from '../../../components/STKPushSentModal';
import { useSelector } from 'react-redux';
import { debitNoteTypeColor } from '../../../data';

export default function ActivityArrearsTable({ data, handleFetch }) {
    const { moneying } = useSelector((state) => state.money);

    const [open, setopen] = useState(false);
    const [selectedItem, setselectedItem] = useState({});
    const [dataSource, setdataSource] = useState(data);

    function handleCancel() {
        setopen(false);
        setselectedItem({});
    }

    async function handleAction(item) {
        await setselectedItem(item);
        await setopen(true);
    }

    useEffect(() => {
        setdataSource(data);
    }, [data]);

    useEffect(() => {}, [dataSource]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'schCreatedDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Type',
            dataIndex: 'schSourceType',
            render: (item) => {
                const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item?.split('_')?.join(' '))}</span>
                    </div>
                );
            },
        },
        {
            title: 'Source',
            dataIndex: 'schParticular',
        },
        {
            title: 'Description',
            dataIndex: 'schDescription',
            render: (item) => <span>{item ?? ' - '}</span>,
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'schContAmount',
            render: (item) => <button type="button">{formatMoney(item, 'KES')}</button>,
        },
        {
            title: 'Action',
            render: (item) => (
                <button disabled={moneying} onClick={() => handleAction(item)} type="button">
                    {moneying && selectedItem === item ? <Spin className="blu_spin" /> : 'Pay'}
                </button>
            ),
        },
    ];

    return (
        <>
            <Table
                rowKey="schId"
                className="mt-[1.5rem] !w-full"
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                scroll={{
                    x: 1200,
                }}
            />
            <STKPushSentModal handleFetch={handleFetch} open={open} handleCancel={handleCancel} selectedItem={selectedItem} />
        </>
    );
}
