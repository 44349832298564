import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SubHeader from './layout/SubHeader';
import axiosInstance from './instance';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { handleUser } from './features/auth/authSlice';
import { fetchChamas } from './features/fetch/fetchSlice';

export const OnboardingRoute = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const { isActive } = useSelector((state) => state.auth);

	const token = localStorage.getItem('token');	

	axiosInstance.defaults.headers.common = {'Authorization': `Bearer ${token}`}

	async function handleInit() {
		if (token) {
			await handleDecodeToken();
			await dispatch(fetchChamas())
		}
	}

	async function handleDecodeToken() {
		try {
			let tokenObj = jwtDecode(token);
			await dispatch(handleUser(tokenObj));
			if (tokenObj?.isOnBoard) {
				return navigate("/home")
			}
		} catch (error) {
			await dispatch(handleUser({}));
		}
	}

	useEffect(() => {
		axiosInstance.defaults.headers.common = {'Authorization': `Bearer ${token}`}
		handleInit();
	}, [token, isActive]);

	if (!isActive && !token) {
		return <Navigate to='/login' replace />;
	}

	return (
		<>
			<div className='flex flex-col w-full'>
				<div className='flex flex-col w-full sticky top-0 z-[10]'>
					<SubHeader />
				</div>
				<div className='w-full h-full flex justify-center items-center'>
					<Outlet />
				</div>
			</div>
		</>
	);
};
