import { Checkbox, Col, DatePicker, Dropdown, Input, Row } from 'antd';
import { useState } from 'react';

export default function StatementFilter() {
	const [open, setopen] = useState(false);

	function handleOpenChange() {
		setopen(!open);
	}

	const filterItems = [
		{
			key: 1,
			label: (
				<>
					<div className='w-full flex flex-col gap-[1.25rem]'>
						<div className='flex items-center justify-between w-full'>
							<button
								onClick={() => console.log('I was clicked')}
								className='flex items-center gap-[.25rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M18 6L6 18M18 18L6 6'
										stroke='black'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
								</svg>
								<span className='heading_4'>Filter</span>
							</button>

							<span className='paragraph_2 !text-[#10101E]'>Clear All</span>
						</div>

						<Input
							placeholder='Search'
							type='search'
							className='filter_search'
							prefix={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M16.9265 17.0401L20.3996 20.4001M11.3996 7.2001C13.3878 7.2001 14.9996 8.81187 14.9996 10.8001M19.2796 11.4401C19.2796 15.77 15.7695 19.2801 11.4396 19.2801C7.1097 19.2801 3.59961 15.77 3.59961 11.4401C3.59961 7.11018 7.1097 3.6001 11.4396 3.6001C15.7695 3.6001 19.2796 7.11018 19.2796 11.4401Z'
										stroke='black'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
								</svg>
							}
						/>

						<div className='flex items-center justify-between w-full'>
							<span className='label_2 !text-[#10101E] !font-medium'>
								Filter by{' '}
							</span>

							<button className='flex items-center gap-[.5rem]'>
								<span className='paragraph_2 !text-[#10101E]'>Clear all</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M7.2002 14.4001L12.0002 9.6001L16.8002 14.4001'
										stroke='black'
										strokeWidth='1.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</button>
						</div>

						<Checkbox.Group
							style={{
								width: '100%',
								padding: '.5rem',
							}}>
							<div className='grid grid-cols-1 gap-[.5rem]'>
								<Checkbox className='filter_checkbox' value='All'>
									All
								</Checkbox>
								<Checkbox className='filter_checkbox' value='Merry-go-round'>
									Merry-go-round
								</Checkbox>
								<Checkbox className='filter_checkbox' value='Loans'>
									Loans
								</Checkbox>
								<Checkbox className='filter_checkbox' value='Investments'>
									Investments
								</Checkbox>
								<Checkbox className='filter_checkbox' value='Penalties'>
									Penalties
								</Checkbox>
								<Checkbox className='filter_checkbox' value='Arrears'>
									Arrears
								</Checkbox>
							</div>
						</Checkbox.Group>

						<div className='flex items-center justify-between w-full'>
							<span className='label_2 !text-[#10101E] !font-medium'>Date</span>

							<button className='flex items-center gap-[.5rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M7.2002 14.4001L12.0002 9.6001L16.8002 14.4001'
										stroke='black'
										strokeWidth='1.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</button>
						</div>

						<div className='flex items-center gap-[.75rem]'>
							<DatePicker
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M6.51468 7.5H17.4004M5.77182 1.5V3.30022M18.0004 1.5V3.3M21.6004 6.3L21.6004 19.5001C21.6004 21.157 20.2572 22.5001 18.6004 22.5001H5.40039C3.74354 22.5001 2.40039 21.157 2.40039 19.5001V6.3C2.40039 4.64315 3.74353 3.3 5.40039 3.3H18.6004C20.2572 3.3 21.6004 4.64314 21.6004 6.3Z'
											stroke='black'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								}
                                className='filter_date_piker'
								placeholder='From'
							/>
							<DatePicker
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M6.51468 7.5H17.4004M5.77182 1.5V3.30022M18.0004 1.5V3.3M21.6004 6.3L21.6004 19.5001C21.6004 21.157 20.2572 22.5001 18.6004 22.5001H5.40039C3.74354 22.5001 2.40039 21.157 2.40039 19.5001V6.3C2.40039 4.64315 3.74353 3.3 5.40039 3.3H18.6004C20.2572 3.3 21.6004 4.64314 21.6004 6.3Z'
											stroke='black'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								}
                                className='filter_date_piker'
								placeholder='To'
							/>
						</div>

                        <div className='flex items-center gap-[1rem]'>
                            <button className='filter_btn_light'>Clear all</button>
                            <button className='filter_btn_dark'>Apply filters</button>
                        </div>
					</div>
				</>
			),
		},
	];

	return (
		<>
			<div className='search'>
				<Dropdown
					disabled
					overlayClassName='filter_dropdown'
					menu={{
						items: filterItems,
					}}
					open={open}
					onOpenChange={handleOpenChange}
					trigger={['click']}
					placement='bottomLeft'>
					<button className='w-full flex justify-between items-center'>
						<span className='flex flex-shrink-0 paragraph_2'>Filter</span>

						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'>
							<path
								d='M8 17H24'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M3 11H29'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M13 23H19'
								stroke='#343330'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</button>
				</Dropdown>
			</div>
		</>
	);
}
