import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import ShareInviteModal from './ShareInviteModal';
import useModalToggle from '../../../custom_hooks/useModalToggle';

export default function MemberInviteOptionsModal({ isOpen, handleClose }) {
    const { handleCancel, handleOpen, open } = useModalToggle();

    function handleShare(){
        handleClose()
        handleOpen()
    }

	return (
		<div>
			<Modal
				centered
				className='custom-modal'
				title='Chama group code'
				open={isOpen}
				onCancel={() => handleClose()}
				footer={false}>
				<div className='flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
					<span className='heading_4 !text-white'>Add members</span>
					<button onClick={() => handleClose()}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='white'
							/>
						</svg>
					</button>
				</div>

				<div className='px-[.5rem] lg:px-[2.94rem] pt-[3rem] flex flex-col lg:flex-row items-center justify-center gap-[2.5rem] w-full'>
					<Link to="/members/invite" className='modal_option_card'>
						<div className='modal_option_card_inner'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='53'
								height='50'
								viewBox='0 0 53 50'
								fill='none'>
								<path
									d='M18.3226 20.3125V39.0625H6.875V20.3125H18.3226Z'
									fill='#FFC263'
									fillOpacity='0.8'
								/>
								<path
									d='M46.1245 9.375H6.8756C6.44188 9.375 6.02591 9.53962 5.71922 9.83265C5.41253 10.1257 5.24023 10.5231 5.24023 10.9375V37.5C5.24023 38.3288 5.58483 39.1237 6.19821 39.7097C6.81159 40.2958 7.64352 40.625 8.51097 40.625H44.4891C45.3566 40.625 46.1885 40.2958 46.8019 39.7097C47.4153 39.1237 47.7599 38.3288 47.7599 37.5V10.9375C47.7599 10.5231 47.5876 10.1257 47.2809 9.83265C46.9742 9.53962 46.5582 9.375 46.1245 9.375ZM8.51097 21.875H16.6878V28.125H8.51097V21.875ZM19.9586 21.875H44.4891V28.125H19.9586V21.875ZM44.4891 12.5V18.75H8.51097V12.5H44.4891ZM8.51097 31.25H16.6878V37.5H8.51097V31.25ZM44.4891 37.5H19.9586V31.25H44.4891V37.5Z'
									fill='#121212'
								/>
							</svg>
						</div>

						<span className='paragraph_2 !text-[#333] text-center'>
							Complete a form
						</span>
					</Link>

					<button onClick={handleShare} className='modal_option_card'>
						<div className='modal_option_card_inner'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='53'
								height='50'
								viewBox='0 0 53 50'
								fill='none'>
								<path
									d='M31.4053 37.5V28.125C18.8538 28.125 10.0208 35.3887 6.65599 38.8125C6.53865 38.9327 6.3846 39.0146 6.21577 39.0463C6.04694 39.078 5.87194 39.0581 5.71567 38.9893C5.5594 38.9205 5.42984 38.8063 5.34542 38.663C5.261 38.5198 5.22602 38.3547 5.24548 38.1914C6.77455 25.4785 20.6098 15.625 31.4053 15.625V6.25L47.759 21.875L31.4053 37.5Z'
									fill='#FFC263'
									fillOpacity='0.8'
								/>
								<path
									d='M48.9174 20.7708L32.5637 5.14576C32.3351 4.92711 32.0437 4.77814 31.7265 4.71769C31.4093 4.65723 31.0805 4.68801 30.7816 4.80613C30.4827 4.92426 30.2271 5.12441 30.0473 5.38129C29.8675 5.63817 29.7714 5.94023 29.7713 6.24928V14.1301C24.4686 14.5637 18.6119 17.0442 13.7937 20.9485C7.99222 25.6516 4.3801 31.7122 3.6217 38.0129C3.56243 38.5028 3.66639 38.998 3.91877 39.4282C4.17115 39.8583 4.55911 40.2014 5.02741 40.4087C5.49572 40.616 6.02053 40.6769 6.52714 40.5827C7.03375 40.4885 7.49636 40.244 7.84913 39.884C10.0978 37.5969 18.0988 30.3645 29.7713 29.7278V37.4993C29.7714 37.8083 29.8675 38.1104 30.0473 38.3673C30.2271 38.6241 30.4827 38.8243 30.7816 38.9424C31.0805 39.0605 31.4093 39.0913 31.7265 39.0309C32.0437 38.9704 32.3351 38.8214 32.5637 38.6028L48.9174 22.9778C49.2232 22.6849 49.395 22.288 49.395 21.8743C49.395 21.4605 49.2232 21.0637 48.9174 20.7708ZM33.042 33.7278V28.1243C33.042 27.7099 32.8697 27.3124 32.563 27.0194C32.2563 26.7264 31.8404 26.5618 31.4066 26.5618C25.6665 26.5618 20.0756 27.9934 14.7892 30.8196C12.0969 32.2653 9.58837 34.0034 7.31355 35.9993C8.49919 31.343 11.4878 26.9153 15.9095 23.3313C20.6561 19.4856 26.4494 17.1868 31.4066 17.1868C31.8404 17.1868 32.2563 17.0222 32.563 16.7291C32.8697 16.4361 33.042 16.0387 33.042 15.6243V10.0227L45.4483 21.8743L33.042 33.7278Z'
									fill='#343330'
								/>
							</svg>
						</div>

						<span className='paragraph_2 !text-[#333] text-center'>
							Share invite link
						</span>
					</button>
				</div>
			</Modal>
            <ShareInviteModal open={open} handleCancel={handleCancel} />
		</div>
	);
}
