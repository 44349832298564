import { useSelector } from 'react-redux';
import moment from 'moment';
import { capitalize, formatMoney, getRandomColor } from '../../../utils';

export default function ContributionTypeDetailsCard() {
    const { activeContributionType } = useSelector((state) => state.obj);

    return (
        <>
            <div className="flex items-start gap-[1.25rem] w-full h-[16.75rem] bg-[#F2F4F7] px-[1.5rem] py-[2.75rem]">
                <div className="hidden lg:flex lg:flex-shrink-0 w-[9.125rem] h-[9.125rem]">
                    <img
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(activeContributionType?.rdnType)}&background=${getRandomColor()}&color=fff`}
                        alt="avatar"
                        className="w-full h-full rounded-full object-cover"
                    />
                </div>
                <div className="flex flex-col w-full">
                    <span className="heading_1">{capitalize(activeContributionType?.rdnType)} summary</span>
                    <span className="label_3 !text-[#64748B]">Contribution start date {moment(activeContributionType?.rdnStartDate).format('Do MMM YYYY')}</span>

                    <div className="flex items-center mt-[1.25rem] w-full">
                        <span className="member_info_text w-[17rem]">Contribution amount</span>
                        <span className="member_info_text w-full !font-normal">{formatMoney(activeContributionType?.rdnAmount, 'KES')}</span>
                    </div>

                    <div className="flex items-center my-[.5rem] w-full">
                        <span className="member_info_text w-[17rem]">Contribution frequency</span>
                        <span className="member_info_text w-full !font-normal">{capitalize(activeContributionType?.rdnFrequencyDescription)}</span>
                    </div>

                    <div className="flex items-center w-full">
                        <span className="member_info_text w-[17rem]">Contribution day</span>
                        <span className="member_info_text w-full !font-normal">{activeContributionType?.rdnContributionDay}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
