import { Table } from 'antd';
import moment from 'moment';
import { formatMoney } from '../../../utils';

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
	},
	{
		title: 'Loan Type',
		dataIndex: 'member',
		render: (item) => (
			<>
				<div className='flex w-fit h-[1.5rem] items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem] bg-[#F6F6F6]'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='5'
						height='4'
						viewBox='0 0 5 4'
						fill='none'>
						<circle cx='2.66602' cy='2' r='2' fill='#212121' />
					</svg>
					<span className=''>{item}</span>
				</div>
			</>
		),
	},
    {
		title: 'Status',
		dataIndex: 'member',
		render: (item) => (
			<>
				<div className='flex w-fit h-[1.5rem] items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem] bg-[#F6F6F6]'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='5'
						height='4'
						viewBox='0 0 5 4'
						fill='none'>
						<circle cx='2.66602' cy='2' r='2' fill='#212121' />
					</svg>
					<span className=''>{item}</span>
				</div>
			</>
		),
	},
    {
		title: 'Amount',
		dataIndex: 'trxRef',
		render: (item) => <span>{formatMoney(0, 'KES')}</span>,
	},
];

export default function MemberLoansTable() {
	return (
		<>
			<Table
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={[]}
			/>
		</>
	);
}
