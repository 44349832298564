import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    aprLoading: false,
    openingBalance: [],
    disbursements: [],
    reimbursements: [],
    settlements: [],
    walletTransfers: [],
};

export const fetchOpeningBalance = createAsyncThunk('approvalSlice/fetchOpeningBalance', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/approval/OPEN_BALANCE`).then((res) => res.data?.data?.result);
    return res;
});

export const fetchSettlementApprovals = createAsyncThunk('approvalSlice/fetchSettlementApprovals', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/approval/SETTLEMENT`).then((res) => res.data?.data?.result);
    return res;
});

export const fetchDisbursementApprovals = createAsyncThunk('approvalSlice/fetchDisbursementApprovals', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/approval/DISBURSEMENT`).then((res) => res.data?.data?.result);
    return res;
});

export const fetchReimbursementApprovals = createAsyncThunk('approvalSlice/fetchReimbursementApprovals', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/approval/REIMBURSEMENT`).then((res) => res.data?.data?.result);
    return res;
});

export const fetchWalletTransfers = createAsyncThunk('approvalSlice/fetchWalletTransfers', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/approval/WALLET_TRANSFER`).then((res) => res.data?.data?.result);
    return res;
});

export const approvalSlice = createSlice({
    name: 'approval',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchWalletTransfers.pending, (state) => {
                state.aprLoading = true;
            })
            .addCase(fetchWalletTransfers.fulfilled, (state, action) => {
                state.aprLoading = false;
                state.walletTransfers = action.payload;
            })
            .addCase(fetchWalletTransfers.rejected, (state) => {
                state.aprLoading = false;
            })

			.addCase(fetchSettlementApprovals.pending, (state) => {
                state.aprLoading = true;
            })
            .addCase(fetchSettlementApprovals.fulfilled, (state, action) => {
                state.aprLoading = false;
                state.settlements = action.payload;
            })
            .addCase(fetchSettlementApprovals.rejected, (state) => {
                state.aprLoading = false;
            })

            .addCase(fetchDisbursementApprovals.pending, (state) => {
                state.aprLoading = true;
            })
            .addCase(fetchDisbursementApprovals.fulfilled, (state, action) => {
                state.aprLoading = false;
                state.disbursements = action.payload;
            })
            .addCase(fetchDisbursementApprovals.rejected, (state) => {
                state.aprLoading = false;
            })

            .addCase(fetchReimbursementApprovals.pending, (state) => {
                state.aprLoading = true;
            })
            .addCase(fetchReimbursementApprovals.fulfilled, (state, action) => {
                state.aprLoading = false;
                state.reimbursements = action.payload;
            })
            .addCase(fetchReimbursementApprovals.rejected, (state) => {
                state.aprLoading = false;
            })

            .addCase(fetchOpeningBalance.pending, (state) => {
                state.aprLoading = true;
            })
            .addCase(fetchOpeningBalance.fulfilled, (state, action) => {
                state.aprLoading = false;
                state.openingBalance = action.payload;
            })
            .addCase(fetchOpeningBalance.rejected, (state) => {
                state.aprLoading = false;
            });
    },
});

export default approvalSlice.reducer;
export const {} = approvalSlice.actions;
