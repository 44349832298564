import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';
import MembersOpeningBalanceTable from '../tables/MembersOpeningBalanceTable';


export default function MembersOpeningBalanceTab() {

	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem]'>

				<div className='white_card flex-col'>
				<span className='heading_4'>List of opening balances</span>
					<div className='w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]'>
						<div className='flex items-center gap-[1rem] w-full'>
							<div className='w-[13.625rem] '>
								<Search text={'Search'} />
							</div>

							<div className='w-[12.5rem]'>
								<StatementFilter />
							</div>
						</div>

						<MembersOpeningBalanceTable />
					</div>
				</div>
			</div>
		</>
	);
}
