import React, { useRef } from 'react';
import useToggle from '../../custom_hooks/useToggle';
import CreateNewReimbursementForm from './CreateNewReimbursementForm';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import AllPurposeOtpModal from '../../components/AllPurposeOtpModal';
import useModalToggle from '../../custom_hooks/useModalToggle';
import RequestSentModal from '../../components/RequestSentModal';
import { applicationUrl, customToast, icon } from '../../utils';
import { deleteEntry, save } from '../../features/save/saveSlice';
import { fetchReimbursements } from '../../features/money/moneySlice';

export default function CreateNewReimbursement({ createNew, handleCancelCreateNew }) {
    const { toggle, show } = useToggle();

    const veriyModal = useModalToggle();
    const success = useModalToggle();

    const [form] = Form.useForm();
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const [dataObj, setdataObj] = useState({
        expenditures: [],
        expendituresAmount: null,
        file: '',
    });

    function handleDataChange({ expenditures, expendituresAmount, file }) {
        setdataObj({
            expenditures: expenditures,
            expendituresAmount: expendituresAmount,
            file: file,
        });
    }

    const [resData, setresData] = useState({});

    async function handleCancelRequest() {
        const deleteObj = {
            url: applicationUrl().url,
            saveUrl: `/api/v2/ndovupay/${resData?.withDrawId}`,
        };
        await dispatch(deleteEntry(deleteObj));
        await setresData({});
        await veriyModal.handleCancel();
        await dispatch(fetchReimbursements());
        await form.resetFields();
        await setdataObj({
            expenditures: [],
            expendituresAmount: null,
            file: '',
        });
    }

    async function handleOkay() {
        await dispatch(fetchReimbursements());
        await success.handleCancel();
        await setdataObj({
            expenditures: [],
            expendituresAmount: null,
            file: '',
        });
        await setresData({});
        await handleCancelCreateNew()
    }

    async function handleNext(code) {
        const saveObj = {
            withDrawLogId: resData?.withDrawId,
            withDrawGpmNumber: resData?.withDrawGpmNumber,
            withDrawCode: code,
            url: applicationUrl().url,
            saveUrl: '/api/v2/ndovupay/finalize-withdraw',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            await veriyModal.handleCancel();
            await success.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'We cannot process your request at the momemnt. Please try again later',
                bdColor: 'error',
                id: 9262,
            });
        }
    }

    const onFinish = async (data) => {
        data.withDrawType = 'MPESA';
        data.withDrawCategory = 'REIMBURSEMENT';
        data.withDrawExpAmount = dataObj.expendituresAmount;
        data.withDrawReceipt = dataObj.file;

        delete data?.withExpeDescription;

        const saveObj = {
            ...data,
            url: applicationUrl().url,
            saveUrl: '/api/v2/ndovupay/initiate-withdraw',
        };

        const res = await dispatch(save(saveObj));

        if (res?.payload?.success) {
            for (const originalItem of dataObj?.expenditures) {
                const newDataObj = {
                    wtdWithDrawId: res?.payload?.data?.result?.withDrawId,
                    wtdAmount: originalItem?.withAmount,
                    wtdParticular: originalItem?.withItem,
                    wtdName: originalItem?.withItem,
                    wtdDescription: originalItem?.withItem,
                    url: applicationUrl().url,
                    saveUrl: '/api/withdrawal/detail',
                };

                try {
                    const res = await dispatch(save(newDataObj));

                    if (!res?.payload?.success) {
                        customToast({
                            content: `Some items could not be saved.`,
                            bdColor: 'error',
                            id: 893773,
                        });
                    }
                } catch (error) {
                    customToast({
                        content: `Some items could not be saved.`,
                        bdColor: 'error',
                        id: 893773,
                    });
                }
            }

            await setresData(res?.payload?.data?.result);
            await veriyModal.handleOpen();
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'We cannot process your request at the momemnt. Please try again later',
                bdColor: 'error',
                id: 8380983,
            });
        }
    };

    async function handleBack() {
        await toggle();
        await handleCancelCreateNew();
        await form.resetFields();
        await setdataObj({
            expenditures: [],
            expendituresAmount: null,
            file: '',
        });
    }

    return (
        <>
            {show || createNew ? (
                <>
                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        <button
                            onClick={() => {
                                toggle();
                                handleCancelCreateNew();
                            }}
                            type="button"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M16.0007 26.1673L5.83398 16.0007L16.0007 5.83398L17.434 7.26732L9.66732 15.0007H26.1673V17.0007H9.66732L17.434 24.734L16.0007 26.1673Z" fill="#212121" />
                            </svg>
                        </button>
                        <span className="heading_4">Make a request</span>
                        <span className="paragraph_1">Please fill in the following details</span>
                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="reimbursementForm"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                width: '100%',
                            }}
                            scrollToFirstError
                            form={form}
                        >
                            <CreateNewReimbursementForm handleBack={handleBack} handleDataChange={handleDataChange} />
                        </Form>
                    </div>
                </>
            ) : (
                <>
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        {icon}

                        <span className="heading_5 my-[1.25rem]">Reimbursement request</span>

                        <span className="paragraph_2">Reimbursement requests you make will show up here.</span>

                        <div className="w-[15rem] mt-[2rem]">
                            <button onClick={toggle} className="cstm-btn-2" type="button">
                                Create request
                            </button>
                        </div>
                    </div>
                </>
            )}

            <AllPurposeOtpModal open={veriyModal.open} handleNext={handleNext} handleCancel={handleCancelRequest} />
            <RequestSentModal
                open={success.open}
                handleOkay={handleOkay}
                text={'Reimbursement request sent to signatories. Funds will reflect after the request has been approved'}
                header={'Reimbursement request sent'}
            />
        </>
    );
}
