import React, { useEffect, useState } from 'react';
import { Dropdown, Form, InputNumber, Popconfirm, Select, Table } from 'antd';
import { applicationUrl, capitalize, customToast, formatMoney } from '../../../utils';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import AddMemberToContributionModal from '../modals/AddMemberToContributionModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContributionTypeMembers } from '../../../features/fetch/fetchSlice';
import TableLoading from '../../../components/TableLoading';
import { deleteEntry, update } from '../../../features/save/saveSlice';
import { membersRoleColors } from '../../../data';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode =
        inputType === 'money' ? (
            <InputNumber prefix={'KES'} min={1} id="input-focus" className="input" variant="borderless" />
        ) : (
            // <InputNumber min={1} placeholder={'Edit number of seats'} id="input-focus" className="input" variant="borderless" />
            <Select
                suffixIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                        <path
                            d="M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z"
                            fill="#212121"
                        />
                    </svg>
                }
                options={[1, 2, 3, 4, 5].map((item, index) => {
                    return {
                        label: (
                            <div key={index}>
                                <span>{item}</span>
                            </div>
                        ),
                        value: item,
                    };
                })}
            />
        );
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    className="table-form-item"
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Field is required`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default function MemberManagementTab() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { open, handleCancel, handleOpen } = useModalToggle();
    const { activeContributionType } = useSelector((state) => state.obj);
    const { contributionTypeMembers, loading } = useSelector((state) => state.fetch);

    const [editingKey, setEditingKey] = useState('');
    const [selectedObj, setselectedObj] = useState({});

    const isEditing = (record) => record?.recurId === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record?.recurId);
    };

    const cancel = () => {
        setEditingKey('');
    };

    async function handleFetch() {
        await dispatch(fetchContributionTypeMembers(activeContributionType?.rdnId));
    }

    const handleDelete = async () => {
        const values = {};
        values.url = applicationUrl().url;
        values.saveUrl = `/api/v1/debt-note/${selectedObj?.recurId}`;

        const res = await dispatch(deleteEntry(values));

        if (res?.payload?.success) {
            await customToast({
                content: res?.payload?.messages?.message,
                bdColor: 'success',
                id: 83763989737887,
            });
            await dispatch(fetchContributionTypeMembers(activeContributionType?.rdnId));
        } else {
            customToast({
                content: res?.payload?.messages?.message ?? 'An error occured',
                bdColor: 'error',
                id: 733773,
            });
        }
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...contributionTypeMembers];
            const index = newData.findIndex((item) => key === item?.recurId);
            if (index > -1) {
                const item = newData[index];

                const updatedObj = {
                    ...item,
                    ...row,
                };

                let saveObj = {
                    data: {
                        rdnId: activeContributionType?.rdnId,
                        recurId: updatedObj?.recurId,
                        recurRdnAmount: updatedObj?.recurRdnAmount,
                        recurNoOfSeat: updatedObj?.recurNoOfSeat,
                    },
                    url: applicationUrl().url,
                    saveUrl: '/api/v1/debt-note/update',
                };

                const res = await dispatch(update(saveObj));

                if (res?.payload?.success) {
                    await customToast({
                        content: res?.payload?.messages?.message,
                        bdColor: 'success',
                        id: 827728278,
                    });
                    await handleFetch();
                    await setEditingKey('');
                } else {
                    customToast({
                        content: res?.payload?.messages?.message ?? 'An error occured',
                        bdColor: 'error',
                        id: 93773737910,
                    });
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const items = [
        {
            key: '1',
            label: (
                <div onClick={() => edit(selectedObj)} className="pointer w-full">
                    Edit
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <Popconfirm
                    okText="Confirm"
                    cancelText="Cancel"
                    cancelButtonProps={{
                        style: {
                            background: '#D3D3D9',
                            color: '#212143',
                            fontWeight: 500,
                            fontSize: '.875rem',
                            borderRadius: '0.375rem',
                            padding: '.5rem',
                            width: 'fit-content',
                            height: '2.0625rem',
                            marginRight: 10,
                        },
                    }}
                    okButtonProps={{
                        style: {
                            background: '#212121',
                            color: '#fff',
                            fontWeight: 500,
                            fontSize: '.875rem',
                            borderRadius: '0.375rem',
                            padding: '.5rem',
                            width: 'fit-content',
                            height: '2.0625rem',
                        },
                    }}
                    title="Are you sure to you want to remove this member?"
                    onConfirm={handleDelete}
                >
                    <div className="pointer w-full">Remove member</div>
                </Popconfirm>
            ),
        },
    ];

    const columns = [
        {
            title: 'Member Name',
            dataIndex: 'recurGpmName',
        },
        {
            title: 'Role',
            dataIndex: 'member',
            render: (item) => {
                const roleColor = membersRoleColors.find((role) => role.label === item?.gpmRoleName)?.value

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item?.gpmRoleName)}</span>
                    </div>
                );
            },
        },
        {
            title: 'Mobile no.',
            dataIndex: 'recurPhoneNumber',
        },
        {
            title: 'No of Seats',
            dataIndex: 'recurNoOfSeat',
            editable: true,
        },
        {
            title: 'Contribution Amount (KES)',
            dataIndex: 'recurRdnAmount',
            render: (_, record) => <span>{formatMoney(record?.recurRdnAmount, 'KES')}</span>,
            editable: true,
        },
        {
            title: 'Total contribution (KES)',
            render: (_, record) => <span>{formatMoney(record?.recurNoOfSeat * record?.recurRdnAmount, 'KES')}</span>,
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div className="flex w-fit items-center gap-[1rem]">
                        <button onClick={cancel} className="modal_btn_light" type="button">
                            Cancel
                        </button>
                        <button type="button" onClick={() => save(record?.recurId)} className="modal_btn_dark">
                            Save
                        </button>
                    </div>
                ) : (
                    <Dropdown
                        overlayStyle={{
                            width: '11.8125rem',
                            margin: '.5rem',
                            boxShadow: ' 0px 1px 12px 0px rgba(25, 27, 35, 0.10)',
                        }}
                        overlayClassName="avatar_dropdown"
                        arrow
                        menu={{
                            items: items,
                        }}
                        onOpenChange={(open) => {
                            if (open) {
                                setselectedObj(record);
                            } else {
                                setselectedObj({});
                            }
                        }}
                        trigger={['click']}
                        placement="bottom"
                    >
                        <button type="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path
                                    d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5Z"
                                    fill="#343330"
                                />
                                <path d="M24.5 17.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5C23.6716 14.5 23 15.1716 23 16C23 16.8284 23.6716 17.5 24.5 17.5Z" fill="#343330" />
                                <path d="M7.5 17.5C8.32843 17.5 9 16.8284 9 16C9 15.1716 8.32843 14.5 7.5 14.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5Z" fill="#343330" />
                            </svg>
                        </button>
                    </Dropdown>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                dataIndex: col.dataIndex,
                inputType: col.dataIndex === 'recurNoOfSeat' ? 'number' : 'money',
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    useEffect(() => {
        handleFetch();
    }, []);

    useEffect(() => {}, [contributionTypeMembers]);

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem] mt-[1.69rem]">
                <div className="white_card flex-col">
                    <div className="flex flex-col w-full justify-center items-center">
                        <span className="heading_4">Members managements</span>
                        <span className="paragraph_1 mt-[.5rem] text-center">
                            Manage/exempt specific members and override their contribution amount <br />
                            <span className="italic">(Members exempted are not removed from the chama group)</span>
                        </span>
                    </div>
                    {loading ? (
                        <TableLoading />
                    ) : (
                        <Form form={form} component={false}>
                            <Table
                                className="mt-[1.25rem]"
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                rowKey={'recurId'}
                                dataSource={contributionTypeMembers}
                                columns={mergedColumns}
                                scroll={{
                                    x: 1200,
                                }}
                                footer={() => {
                                    return (
                                        <>
                                            <div className="flex items-center w-full justify-between gap-[1.25rem] p-[1rem]">
                                                <button type="button" onClick={handleOpen} className="flex items-center gap-[.5rem]">
                                                    <div className="black-round-btn">
                                                        <svg className="z-[100]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                                        </svg>
                                                    </div>

                                                    <span className="role_btn_text">Add member</span>
                                                </button>
                                            </div>
                                        </>
                                    );
                                }}
                            />
                        </Form>
                    )}
                </div>
            </div>

            <AddMemberToContributionModal open={open} handleCancel={handleCancel} />
        </>
    );
}
