import { useState } from 'react';

export default function useFilterArray(initVal) {
	const [filterArray, setfilterArray] = useState(initVal);

	function handleFilter(arr) {
		setfilterArray(arr);
	}
	
	return { filterArray, handleFilter };
}
