import { Table } from 'antd';
import moment from 'moment';
import { capitalize, capitalizeFirstLetter, formatMoney } from '../../../utils';
import { useEffect } from 'react';
import TableLoading from '../../../components/TableLoading';
import { useSelector } from 'react-redux';

const columns = [
    {
        title: 'Date',
        dataIndex: 'createdDate',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
    },
    {
        title: 'Source account',
        dataIndex: 'sourceWallet',
        render: (item) => (
            <span>
                {capitalize(item?.cwName)} | {String(item?.cwCode).toUpperCase()}
            </span>
        ),
    },
    {
        title: 'Recipient account',
        dataIndex: 'recipientWallet',
        render: (item) => (
            <span>
                {capitalize(item?.cwName)} | {String(item?.cwCode).toUpperCase()}
            </span>
        ),
    },
    {
        title: 'Reason',
        dataIndex: 'wtReason',
        render: (item) => <span>{capitalizeFirstLetter(item)}</span>,
    },
    {
        title: 'Amount',
        dataIndex: 'wtAmount',
        render: (item) => <span>{formatMoney(item, 'KES')}</span>,
    },
];

export default function ApprovedAccountTransfersTable() {
    const { moneying, accountTransfers } = useSelector((state) => state.money);

    let filteredArray = accountTransfers?.filter((item) => item?.wtStatus === 'COMPLETED' || item?.wtStatus === 'SUCCESS');

    useEffect(() => {}, [accountTransfers]);    

    if (moneying) return <TableLoading />;

    return (
        <>
            <Table
                scroll={{
                    x: 1200,
                }}
                rowKey="wtId"
                className="mt-[1.5rem] !w-full"
                pagination={false}
                columns={columns}
                dataSource={filteredArray}
            />
        </>
    );
}
