import { Table } from 'antd';
import TableLoading from '../../../components/TableLoading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPivot } from '../../../features/fetch/fetchSlice';
import { useEffect } from 'react';
import { formatMoney } from '../../../utils';

export default function GroupContributionTable({ limit }) {
    const dispatch = useDispatch();

    const { activeChama } = useSelector((state) => state.obj);
    const { pivotData = [], loading } = useSelector((state) => state.fetch);

    const dateKeys = Array.from(new Set(pivotData?.flatMap((item) => Object.keys(item)).filter((key) => key !== 'Member')));

    const columns = [
        {
            title: 'Member',
            dataIndex: 'Member',
            key: 'Member',
            fixed: 'left',
        },
        ...dateKeys.map((date) => ({
            title: date,
            dataIndex: date,
            render: (item) => <span>{typeof item === 'number' ? formatMoney(item, 'KES') : item}</span>,
            key: date,
        })),
    ];

    const dataSource = pivotData?.map((item, index) => ({
        key: index,
        ...item,
    }));

    async function handleFetch() {
        dispatch(fetchPivot(activeChama?.custId));
    }

    useEffect(() => {
        handleFetch();
    }, []);

    useEffect(() => {}, [pivotData]);

    if (loading) return <TableLoading />;

    return (
        <>
            <Table
                rowKey="key"
                className="mt-[1.5rem] !w-full"
                columns={columns}
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                dataSource={dataSource}
                scroll={{
                    x: 1000,
                }}
            />
        </>
    );
}
