import { useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { roleTypes } from '../../../data';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChamaRoles } from '../../../features/fetch/fetchSlice';
import { applicationUrl, customToast } from '../../../utils';
import { save } from '../../../features/save/saveSlice';
import ViewRoleModal from '../modals/ViewRoleModal';

export default function ChamaRolesStep({ handleNext, form, handleBack }) {
	const dispatch = useDispatch();

	const { newChamaData } = useSelector((state) => state.obj);
	const { roles } = useSelector((state) => state.fetch);
	const { saving } = useSelector((state) => state.save);

	const [add, setadd] = useState(false);
	const [roleValue, setroleValue] = useState('');
	const [open, setopen] = useState(false);
	const [roleObj, setroleObj] = useState({});

	function handleCancel() {
		setopen(false);
	}

	function handleOpen(item) {
		setopen(true);
		setroleObj(item);
	}

	async function handlefetch() {
		await dispatch(fetchChamaRoles(newChamaData?.custId));
	}

	async function handleSaveRole() {
		try {
			const data = {};

			data.customerId = newChamaData?.custId;
			data.roleName = roleValue;
			data.permissionIds = [];
			data.url = applicationUrl().url;
			data.saveUrl = '/api/v1/role';

			const res = await dispatch(save(data));

			if (res?.payload?.success) {
				await setadd(false);
				handlefetch();
				await customToast({
					content: res?.payload?.messages?.message ?? 'Role added',
					bdColor: 'success',
				});
			} else {
				customToast({
					content: res?.payload?.messages?.message ?? 'An error occured',
					bdColor: 'error',
				});
			}
		} catch {}
	}

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
		handlefetch();
	}, []);

	const colors = ['#F9FCFF', '#F9FFFA', '#FDF9FF'];

	return (
		<>
			<div className='flex flex-col w-full'>
				{roles?.length && !add ? (
					<>
						<div className='flex flex-col justify-center items-center w-full gap-[.38rem]'>
							{roles.map((item, index) => {
								let color = colors[index];
								return (
									<div
										key={index}
										style={{
											background: color,
										}}
										className='flex gap-[1.5rem] justify-between items-center w-full lg:w-[35rem] rounded-[0.5rem] h-[4.625rem] p-[0.75rem]'>
										<div className='flex flex-col gap-[.25rem]'>
											<span className='role_header'>{item?.roleName}</span>
											<span className='role_text'>All permissions</span>
										</div>
										<button
											type='button'
											onClick={() => handleOpen(item)}
											className='label_2 !text-[#377FFF]'>
											View
										</button>
									</div>
								);
							})}

							<button
								type='button'
								onClick={() => setadd(true)}
								className='mt-[1.63rem] w-full lg:w-[35rem] flex items-center gap-[.5rem]'>
								<div className='black-round-btn'>
									<svg
										className='z-[100]'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M11.9998 4.7998L11.9998 19.1998M19.1998 11.9998L4.7998 11.9998'
											stroke='white'
											strokeWidth='1.5'
											strokeLinecap='round'
										/>
									</svg>
								</div>

								<span className='role_btn_text'>Add another role</span>
							</button>
						</div>

						<div className='py-[2.31rem] mt-[5rem] w-full flex justify-center items-center'>
							<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
								<button
									// disabled={saving}
									onClick={() => handleNext()}
									type='button'
									className='cstm-btn-2'>
									Next
								</button>

								<button
									onClick={() => handleBack()}
									type='button'
									className='cstm-btn'>
									Previous
								</button>
							</div>
						</div>
					</>
				) : add ? (
					<>
						<Form.Item
							label='Choose role'
							rules={[
								{
									required: true,
									message: 'Field is required',
								},
							]}>
							<Select
								onChange={(val) => setroleValue(val)}
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='21'
										height='21'
										viewBox='0 0 21 21'
										fill='none'>
										<path
											d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
											fill='#212121'
										/>
									</svg>
								}
								options={roleTypes}
							/>
						</Form.Item>

						<Form.Item
							label='The selected role has the following set of permissions'
							name='rolePermissions'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Select
								disabled
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='21'
										height='21'
										viewBox='0 0 21 21'
										fill='none'>
										<path
											d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
											fill='#212121'
										/>
									</svg>
								}
								options={[]}
							/>
						</Form.Item>

						<div className='py-[2.44rem] w-full flex justify-center items-center'>
							<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
								<button
									disabled={saving}
									onClick={handleSaveRole}
									type='button'
									className='cstm-btn-2'>
									{saving ? <Spin /> : 'Save'}
								</button>

								<button
									onClick={() => setadd(false)}
									type='button'
									className='cstm-btn'>
									Cancel
								</button>
							</div>
						</div>
					</>
				) : (
					<>
						<div className='flex flex-col w-full justify-center text-center'>
							<span className='label_2'>Add a role</span>
							<span className='paragraph_2'>
								Add key roles to your group. <br />
								<span className='!italic'>
									For example, Chairperson, Treasurer, Secretary.
								</span>
								<br /> You will be able to assign these roles to members when
								they join the group
							</span>

							<div className='py-[2.37rem] w-full flex justify-center items-center'>
								<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
									<button
										// disabled={saving}
										onClick={() => setadd(true)}
										type='button'
										className='cstm-btn-2'>
										Add
									</button>

									<button
										onClick={() => handleBack()}
										type='button'
										className='cstm-btn'>
										Previous
									</button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<ViewRoleModal open={open} handleCancel={handleCancel} item={roleObj} />
		</>
	);
}
