import React, { useState } from 'react';
import '../monthPicker.css';
import { Dropdown } from 'antd';
import { getCalendarSup } from '../utils';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const dateStyles = {
    container: {
        width: '100%',
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: 'white',
        textAlign: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    arrowButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '10px',
        marginTop: '20px',
    },
    dayButton: {
        padding: '10px',
        fontSize: '16px',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '6px',
        transition: 'background-color 0.3s',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    cancelButton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#555',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        borderRadius: '6px',
        cursor: 'pointer',
    },
    applyButton: {
        padding: '10px 20px',
        fontSize: '16px',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
    },
};

const MonthPicker = ({ handleContributionDay, dateValue }) => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [open, setopen] = useState(false);
    const [showDate, setshowDate] = useState(false);

    const daysInMonth = Array.from({ length: 30 }, (_, i) => i + 1);

    function handleOpenChange() {
        setopen(!open);
    }

    const handleDayClick = (day) => {
        setSelectedDay(day);
    };

    const handleCancel = async () => {
        await handleContributionDay('');
        await setopen(false);
        await setSelectedMonth(null);
        await setshowDate(false);
        await setSelectedDay(null);
    };

    const handleMonthClick = (month) => {
        setSelectedMonth(month);
    };

    const handleNext = () => {
        if (selectedMonth) {
            setshowDate(true);
        }
    };

    const handleApply = async () => {
        await handleContributionDay(`${selectedMonth}, on ${getCalendarSup(selectedDay)}`);
        await setSelectedMonth(null);
        await setopen(false);
        await setshowDate(false);
        await setSelectedDay(null);
    };

    const calendarItems = [
        {
            label: showDate ? (
                <div style={dateStyles.container}>
                    <div style={dateStyles.header}>
                        <button type="button" style={dateStyles.arrowButton}>
                            ←
                        </button>
                        <h2 style={dateStyles.title}>Select day of the month</h2>
                        <button type="button" style={dateStyles.arrowButton}>
                            →
                        </button>
                    </div>

                    <div style={dateStyles.grid}>
                        {daysInMonth.map((day) => (
                            <button
                                type="button"
                                key={day}
                                onClick={() => handleDayClick(day)}
                                style={{
                                    ...dateStyles.dayButton,
                                    backgroundColor: day === selectedDay ? '#FFC107' : 'transparent',
                                }}
                            >
                                {day}
                            </button>
                        ))}
                    </div>

                    <div style={dateStyles.footer}>
                        <button type="button" style={dateStyles.cancelButton} onClick={handleCancel}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            style={{
                                ...dateStyles.applyButton,
                                backgroundColor: selectedDay ? '#FFC107' : '#f0f0f0',
                                color: selectedDay ? 'black' : '#ccc',
                            }}
                            onClick={handleApply}
                            disabled={!selectedDay}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            ) : (
                <div className="month-selector">
                    <div className="header">
                        <button type="button" onClick={handleCancel}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M10.8333 18L5 12M5 12L10.8333 6M5 12H19" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <h3>Choose month</h3>
                        <div></div>
                    </div>
                    <div className="month-grid">
                        {months.map((month, index) => (
                            <button type="button" key={index} onClick={() => handleMonthClick(month)} className={`month-btn ${selectedMonth === month ? 'selected' : ''}`}>
                                {month}
                            </button>
                        ))}
                    </div>
                    <div className="footer">
                        <button type="button" className="cancel-btn" onClick={handleCancel}>
                            Cancel
                        </button>
                        <button type="button" className="next-btn" onClick={handleNext} disabled={!selectedMonth}>
                            Next
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Dropdown
            overlayClassName="avatar_dropdown"
            onOpenChange={handleOpenChange}
            arrow
            open={open}
            menu={{
                items: calendarItems,
            }}
            trigger={['click']}
            placement="bottom"
        >
            <button type="button">{dateValue?.length ? dateValue : 'Choose month'}</button>
        </Dropdown>
    );
};

export default MonthPicker;
