import { Table, Tag } from 'antd';
import moment from 'moment';
import { getRandomColor } from '../../../utils';

const columns = [
	{
		title: 'Date',
		dataIndex: 'date',
		render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
	},
	{
		title: 'Member',
		dataIndex: 'member',
		render: (item) => (
			<>
				<div className='w-full flex items-center gap-[.5rem]'>
					<img
						src={`https://ui-avatars.com/api/?name=${encodeURIComponent('MN')}&background=${getRandomColor()}&color=fff`}
						className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
						alt='avatar'
					/>
					<span>Member Name</span>
				</div>
			</>
		),
	},
	{
		title: 'Reason',
		dataIndex: 'trxRef',
		render: (item) => (
			<>
				<Tag
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='4'
							height='5'
							viewBox='0 0 4 5'
							fill='none'>
							<circle cx='2' cy='2.5' r='2' fill='#121212' />
						</svg>
					}
					color='#F2F4F7'>
					Late contribution
				</Tag>
			</>
		),
	},
	{
		title: 'Status',
		dataIndex: 'trxRef',
		render: (item) => (
			<>
				<Tag
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='4'
							height='5'
							viewBox='0 0 4 5'
							fill='none'>
							<circle cx='2' cy='2.5' r='2' fill='#121212' />
						</svg>
					}
					color='rgba(147, 205, 147, 0.22)'>
					{item}
				</Tag>
			</>
		),
	},
	{
		title: 'Amount',
		dataIndex: 'seats',
	},
	{
		title: 'Assigned by',
		dataIndex: 'assigned',
		render: (item) => (
			<>
				<div className='w-full flex items-center gap-[.5rem]'>
					<img
						src={`https://ui-avatars.com/api/?name=${encodeURIComponent('MN')}&background=${getRandomColor()}&color=fff`}
						className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
						alt='avatar'
					/>
					<span>Member Name</span>
				</div>
			</>
		),
	},
	{
		title: 'Action',
		render: (item) => <button type='button'>Waiver</button>,
	},
];

export default function MembersPenaltyTable() {
	return (
		<>
			<Table
				rowSelection={true}
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				scroll={{
					x: 1200,
				}}
				columns={columns}
				dataSource={[]}
			/>
		</>
	);
}
