import { Form, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleAuthData, register } from '../../../features/auth/authSlice';
import { customToast } from '../../../utils';

export default function LocationStep({ handleNext, handleBack, form }) {
	const dispatch = useDispatch();

	const { authLoading, authData } = useSelector((state) => state.auth);

	const [cities, setcities] = useState([]);
	const [countries, setcountries] = useState([])
	const [loading, setloading] = useState(false)

	async function handleInit() {
		setloading(true)
		const res = await fetch('https://abzed-country-city.vercel.app/countryCity')

		if (!res.ok) {
			setcountries([])
			setloading(false)
			return
		}

		const data = await res.json()
		setcountries(data)
		setloading(false)
		
	}

	async function handleSubmit() {
		try {
			await form.validateFields(['usrCountry']);
			let data = form.getFieldsValue();
			data.usrId = authData?.usrId;

			const res = await dispatch(register(data));

			if (res?.payload?.success) {
				await dispatch(handleAuthData(res?.payload?.data?.result));
				await handleNext();
			} else {
				customToast({
					content: res?.payload?.messages?.message,
					bdColor: 'error',
					id: 927525767563,
				});
			}
		} catch {}
	}

	async function handleCountryChange(value) {
		await setcities([]);
		await form.setFieldsValue({ usrTown: '' });
		let cityArray = await countries?.find((item) => item?.name === value);
		await setcities(cityArray?.cities);
	}

	useEffect(() => {}, [cities]);

	useEffect(() => {
		handleInit()
	}, []);

	return (
		<>
			<div className='flex flex-col w-full'>
				<Form.Item
					label='Where do you reside? '
					name='usrCountry'
					extra={loading ? 'Loading countries please wait...' : null}
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Select
						loading={loading}
						showSearch
						onChange={handleCountryChange}
						suffixIcon={loading ? <Spin className='blu_spin' /> :
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						options={countries?.map((item) => {
							return {
								label: item.name,
								value: item.name,
							};
						})}
					/>
				</Form.Item>

				<Form.Item
					label='Choose town / county'
					name='usrTown'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Select
						disabled={loading}
						showSearch
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						options={cities?.map((item) => {
							return {
								label: item.name,
								value: item.name,
							};
						})}
					/>
				</Form.Item>

				<div className='py-[2.44rem] w-full flex justify-center items-center'>
					<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
						<button onClick={handleSubmit} type='button' className='cstm-btn-2'>
							{authLoading ? <Spin /> : 'Next'}
						</button>

						<button
							onClick={() => handleBack()}
							type='button'
							className='cstm-btn'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
