import { Dropdown } from 'antd';

export default function PageActions({ pageActions, text, action }) {
    return (
        <>
            <div className="action_btn">
                {typeof pageActions === 'object' ? (
                    <Dropdown
                        overlayClassName="avatar_dropdown"
                        arrow
                        menu={{
                            items: pageActions,
                        }}
                        trigger={['click']}
                        placement="bottom"
                    >
                        <button type="button" className="flex items-center gap-[.25rem] w-fit">
                            <div className="flex flex-shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M11.65 17.95L15.35 10.75H12.5V6L8.675 13.25H11.65V17.95ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                        fill="#FFCD47"
                                    />
                                </svg>
                            </div>
                            {/* <span className="flex lg:hidden flex-shrink-0">{text ?? 'Action'}</span> */}
                            <span className="flex lg:hidden flex-shrink-0"></span>
                            <span className="hidden lg:flex flex-shrink-0">{text ?? 'Quick actions'}</span>
                        </button>
                    </Dropdown>
                ) : (
                    <button onClick={action} type="button" className="flex items-center gap-[.25rem] w-fit">
                        <div className="flex flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M11.65 17.95L15.35 10.75H12.5V6L8.675 13.25H11.65V17.95ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12933 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                                    fill="#FFCD47"
                                />
                            </svg>
                        </div>
                        <span className="flex lg:hidden flex-shrink-0"></span>
                        <span className="hidden lg:flex flex-shrink-0">{text ?? 'Quick actions'}</span>
                    </button>
                )}
            </div>
        </>
    );
}
