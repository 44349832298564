import userPlus from '../../assets/svg/UserPlus.svg';
import users from '../../assets/svg/Users.svg';
import coins from '../../assets/svg/Coins.svg';
import OptionsCard from './cards/OptionsCard';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateOnboarding } from '../../features/auth/authSlice';

export default function OnboardingOptions() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { chamas } = useSelector((state) => state.fetch);

	const [activeOption, setactiveOption] = useState(null);

	function handleActiveOption(item) {
		setactiveOption(item);
	}

	async function handleOptionNavigation() {
		await dispatch(
			updateOnboarding({
				boarded: true,
			})
		);

		if (activeOption === 1) {
			return navigate('/chama-features');
		} else if (activeOption === 2) {
			return navigate('/join-chama');
		}
		navigate('/home');
	}

	async function handleSkip() {
		await dispatch(
			updateOnboarding({
				boarded: true,
			})
		);
		await navigate('/home');
	}

	const cardOptionData = [
		{
			key: 1,
			icon: userPlus,
			header: 'Create a chama',
			content: 'Open a chama to manage group savings.',
		},
		{
			key: 2,
			icon: users,
			header: 'Join a chama',
			content: 'Use a code or link to join an existing chama ',
		},
		{
			key: 3,
			icon: coins,
			header: 'Top up',
			content: 'Manage your personal savings and investments',
		},
	];

	return (
		<>
			<div className='onboarding'>
				<span className='heading_1'>Choose an option to get started</span>
				<span className='paragraph_1 mt-[1.5rem]'>
					Manage your financial growth with ease
				</span>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-[.94rem] mt-[2.5rem]'>
					{cardOptionData.map((item, index) => {
						return (
							<div key={index}>
								<OptionsCard
									handleActiveOption={handleActiveOption}
									cardKey={item.key}
									header={item.header}
									icon={item.icon}
									content={item.content}
									activeOption={activeOption}
									disabled={item.disabled}
								/>
							</div>
						);
					})}
				</div>

				<div className='w-[10.625rem] mt-[5rem] flex flex-col gap-[.75rem]'>
					{chamas?.length && !activeOption ? (
						<>
							<button onClick={handleSkip} className='cstm-btn-3'>
								Skip
							</button>
						</>
					) : (
						<>
							{activeOption ? (
								<button onClick={handleOptionNavigation} className='cstm-btn-3'>
									Next
								</button>
							) : (
								<button disabled className='cstm-btn-3-disabled'>
									Next
								</button>
							)}
						</>
					)}

					<button onClick={() => navigate('/onboarding')} className='cstm-btn'>
						Previous
					</button>
				</div>
			</div>
		</>
	);
}
