import { useEffect, useState } from 'react';
import GroupDetailsSubStepOne from './sub_steps/GroupDetailsSubStepOne';
import GroupDetailsSubStepTwo from './sub_steps/GroupDetailsSubStepTwo';

export default function GroupDetailsStep({
	handleNext,
	form,
	handleSubCurrent,
}) {
	const [activeStep, setactiveStep] = useState(0);

	function handleActiveStep(item) {
		setactiveStep(item);
	}

	useEffect(() => {
		handleSubCurrent(activeStep);
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
	}, [activeStep]);

	return (
		<>
			{activeStep === 0 ? (
				<GroupDetailsSubStepOne
					handleActiveStep={handleActiveStep}
					form={form}
				/>
			) : (
				<GroupDetailsSubStepTwo
					handleNext={handleNext}
					handleActiveStep={handleActiveStep}
					form={form}
				/>
			)}
		</>
	);
}
