import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;
const localUrl = 'http://10.10.1.68:8080';

const initialState = {
    optLoading: false,
    chamaFeatures: [],
    banks: [],
};

export const fetchChamaFeatures = createAsyncThunk('optionSlice/fetchChamaFeatures', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/customers/features`).then((res) => res.data.data.result);
    return res;
});

export const fetchBanks = createAsyncThunk('optionSlice/fetchBanks', async () => {
    const res = await axiosInstance.get(`${url}/api/v1/bank`).then((res) => res.data.data.result);
    return res;
});

export const veriBankAccount = createAsyncThunk('optionSlice/veriBankAccount', async (data) => {
    const res = await axiosInstance.get(`https://corporate.ndovupay.org/ndovu/usr/verify_account.action?accountNo=${data?.accountNo}&bankCode=${data?.bankCode}`).then((res) => res.data);
    return res;
});

export const optionSlice = createSlice({
    name: 'option',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(veriBankAccount.pending, (state) => {
                state.optLoading = true;
            })
            .addCase(veriBankAccount.fulfilled, (state, action) => {
                state.optLoading = false;
            })
            .addCase(veriBankAccount.rejected, (state) => {
                state.optLoading = false;
            })

            .addCase(fetchBanks.pending, (state) => {
                state.optLoading = true;
            })
            .addCase(fetchBanks.fulfilled, (state, action) => {
                state.optLoading = false;
                state.banks = action.payload;
            })
            .addCase(fetchBanks.rejected, (state) => {
                state.optLoading = false;
            })

            .addCase(fetchChamaFeatures.pending, (state) => {
                state.optLoading = true;
            })
            .addCase(fetchChamaFeatures.fulfilled, (state, action) => {
                state.optLoading = false;
                state.chamaFeatures = action.payload;
            })
            .addCase(fetchChamaFeatures.rejected, (state) => {
                state.optLoading = false;
            });
    },
});

export default optionSlice.reducer;
export const {} = optionSlice.actions;
