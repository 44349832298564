import { Dropdown } from 'antd';
import React, { useState } from 'react';
import { getCalendarSup } from '../utils';

const styles = {
    container: {
        width: '19.4375rem',
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: 'white',
        textAlign: 'center',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    monthButton: {
        width: '100%',
        padding: '15px',
        fontSize: '16px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        cursor: 'pointer',
        marginBottom: '10px',
        transition: 'background-color 0.3s',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    cancelButton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#555',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        borderRadius: '6px',
        cursor: 'pointer',
    },
    nextButton: {
        padding: '10px 20px',
        fontSize: '16px',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
    },
};

const dateStyles = {
    container: {
        width: '100%',
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: 'white',
        textAlign: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    arrowButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '10px',
        marginTop: '20px',
    },
    dayButton: {
        padding: '10px',
        fontSize: '16px',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '6px',
        transition: 'background-color 0.3s',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    cancelButton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#555',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        borderRadius: '6px',
        cursor: 'pointer',
    },
    applyButton: {
        padding: '10px 20px',
        fontSize: '16px',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
    },
};

const QuarterPicker = ({ handleContributionDay, dateValue }) => {
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [open, setopen] = useState(false);
    const [showDate, setshowDate] = useState(false);

    const daysInMonth = Array.from({ length: 30 }, (_, i) => i + 1);

    function handleOpenChange() {
        setopen(!open);
    }

    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
    };

    const handleDayClick = (day) => {
        setSelectedDay(day);
    };

    const handleCancel = async () => {
        await handleContributionDay('')
        await setSelectedMonth(null);
        await setopen(false);
        await setshowDate(false);
        await setSelectedDay(null);
    };

    const handleNext = () => {
        if (selectedMonth) {
            setshowDate(true);
        }
    };

    const handleApply = async () => {
        await handleContributionDay(`${selectedMonth} of the quarter, on ${getCalendarSup(selectedDay)}`);
        await setopen(false);
        await setshowDate(false);
        await setSelectedDay(null);
        await setSelectedMonth(null);
    };

    const calendarItems = [
        {
            label: showDate ? (
                <div style={dateStyles.container}>
                    <div style={dateStyles.header}>
                        <button type="button" style={dateStyles.arrowButton}>
                            ←
                        </button>
                        <h2 style={dateStyles.title}>Select day of the month</h2>
                        <button type="button" style={dateStyles.arrowButton}>
                            →
                        </button>
                    </div>

                    <div style={dateStyles.grid}>
                        {daysInMonth.map((day) => (
                            <button
                                type="button"
                                key={day}
                                onClick={() => handleDayClick(day)}
                                style={{
                                    ...dateStyles.dayButton,
                                    backgroundColor: day === selectedDay ? '#FFC107' : 'transparent',
                                }}
                            >
                                {day}
                            </button>
                        ))}
                    </div>

                    <div style={dateStyles.footer}>
                        <button type="button" style={dateStyles.cancelButton} onClick={handleCancel}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            style={{
                                ...dateStyles.applyButton,
                                backgroundColor: selectedDay ? '#FFC107' : '#f0f0f0',
                                color: selectedDay ? 'black' : '#ccc',
                            }}
                            onClick={handleApply}
                            disabled={!selectedDay}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            ) : (
                <div style={styles.container}>
                    <h2 style={styles.title}>Select month of the quarter</h2>

                    <button
                        type="button"
                        onClick={() => handleMonthSelect('First month')}
                        style={{
                            ...styles.monthButton,
                            border: selectedMonth === 'First month' ? '1px solid #121212' : '1px solid #E2E9EE',
                        }}
                    >
                        First month of the quarter
                    </button>

                    <button
                        type="button"
                        onClick={() => handleMonthSelect('Second month')}
                        style={{
                            ...styles.monthButton,
                            border: selectedMonth === 'Second month' ? '1px solid #121212' : '1px solid #E2E9EE',
                        }}
                    >
                        Second month of the quarter
                    </button>

                    <button
                        type="button"
                        onClick={() => handleMonthSelect('Third month')}
                        style={{
                            ...styles.monthButton,
                            border: selectedMonth === 'Third month' ? '1px solid #121212' : '1px solid #E2E9EE',
                        }}
                    >
                        Third month of the quarter
                    </button>

                    <div style={styles.footer}>
                        <button type="button" style={styles.cancelButton} onClick={handleCancel}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            style={{
                                ...styles.nextButton,
                                backgroundColor: selectedMonth ? '#FFC107' : '#f0f0f0',
                                color: selectedMonth ? 'black' : '#ccc',
                            }}
                            onClick={handleNext}
                            disabled={!selectedMonth}
                        >
                            Next
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Dropdown
            overlayClassName="avatar_dropdown"
            onOpenChange={handleOpenChange}
            arrow
            open={open}
            menu={{
                items: calendarItems,
            }}
            trigger={['click']}
            placement="bottom"
        >
            <button type="button">{dateValue?.length ? dateValue : 'Choose quarter'}</button>
        </Dropdown>
    );
};

export default QuarterPicker;
