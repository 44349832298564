import { Table } from 'antd';
import moment from 'moment';
import { capitalize, capitalizeFirstLetter, formatMoney, getRandomColor } from '../../../utils';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchAccountTransfers } from '../../../features/money/moneySlice';
import TableLoading from '../../../components/TableLoading';
import { statusColor } from '../../../data';

const columns = [
    {
        title: 'Date',
        dataIndex: 'createdDate',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
    },
    {
        title: 'Source account',
        dataIndex: 'sourceWallet',
        render: item => <span>{capitalize(item?.cwName)} | {String(item?.cwCode).toUpperCase()}</span>
    },
    {
        title: 'Recipient account',
        dataIndex: 'recipientWallet',
        render: item => <span>{capitalize(item?.cwName)} | {String(item?.cwCode).toUpperCase()}</span>    },
    {
        title: 'Status',
        dataIndex: 'wtStatus',
        render: (item) => {
            const roleColor = statusColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6'

            return (
                <div
                    style={{
                        background: roleColor,
                    }}
                    className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{capitalize(item?.split('_')?.join(' '))}</span>
                </div>
            );
        },
    },
    {
        title: 'Reason',
        dataIndex: 'wtReason',
        render: (item) => <span>{capitalizeFirstLetter(item)}</span>,
    },
    {
        title: 'Amount',
        dataIndex: 'wtAmount',
        render: (item) => <span>{formatMoney(item, 'KES')}</span>,
    },
];

export default function AllAccountTransfersTable() {
    const { isLoading } = useFetchDispatch(fetchAccountTransfers);

    const { accountTransfers } = useSelector((state) => state.money);

    useEffect(() => {}, [accountTransfers]);

    if (isLoading) return <TableLoading />;

    return (
        <>
            <Table
                scroll={{
                    x: 1200,
                }}
                rowKey="wtId"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                dataSource={accountTransfers}
            />
        </>
    );
}
