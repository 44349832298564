import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import useToggle from '../../../custom_hooks/useToggle';

export default function ShareInviteModal({ open, handleCancel }) {
	const { show, toggle } = useToggle();

	const { activeChama } = useSelector((state) => state.obj);

	async function handleCopy() {
		await toggle();
		await navigator.clipboard.writeText(
			`To join ${activeChama?.custName.trim()} Chama group:\n- Login or create an account at https://chamify.africa/\n- Click on "Join Chama" and enter the code: ${activeChama?.custInviteCode.trim()}`
		  );
	}

	function handleClose() {
		toggle();
		handleCancel();
	}

	return (
		<>
			<Modal
				centered
				className='custom-modal'
				title='Invite member'
				open={open}
				onCancel={handleClose}
				width={500}
				footer={false}>
				<div className='flex justify-between items-center px-[2.94rem] pt-[2.5rem]'>
					<p className='heading_4 !text-blk3'>Invite link</p>
					{show ? (
						<div className='flex w-fit items-center gap-[.5rem] p-[.5rem] rounded-[0.75rem] bg-[#E7F9E7] h-[2.75rem] -mt-[1rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M3.125 11.25L7.5 15.625L17.5 5.625'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							<span>Link copied</span>
						</div>
					) : null}

					<button onClick={handleClose}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='#212121'
							/>
						</svg>
					</button>
				</div>

				<div className='w-full mt-[1rem] flex flex-col gap-[.5rem] px-[2.94rem]'>
					<span className='paragraph_2 !text-blk3'>
						Share the link below to invite members to the group{' '}
					</span>
				</div>

				<div className='flex flex-col w-full mt-[2.19rem] px-[2.94rem]'>
					<span className='label_2 '>Copy link</span>

					<div className='w-full h-[3rem] px-[1rem] py-[.88rem] flex justify-between items-center rounded-[.375rem] border border-[#212121]  mt-[.81rem]'>
						<span className='label_2'>{`${activeChama?.custName} - joinndovuchama.url`}</span>
						<button type='button' onClick={handleCopy}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M20.25 3H8.25C8.05109 3 7.86032 3.07902 7.71967 3.21967C7.57902 3.36032 7.5 3.55109 7.5 3.75V7.5H3.75C3.55109 7.5 3.36032 7.57902 3.21967 7.71967C3.07902 7.86032 3 8.05109 3 8.25V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H15.75C15.9489 21 16.1397 20.921 16.2803 20.7803C16.421 20.6397 16.5 20.4489 16.5 20.25V16.5H20.25C20.4489 16.5 20.6397 16.421 20.7803 16.2803C20.921 16.1397 21 15.9489 21 15.75V3.75C21 3.55109 20.921 3.36032 20.7803 3.21967C20.6397 3.07902 20.4489 3 20.25 3ZM15 19.5H4.5V9H15V19.5ZM19.5 15H16.5V8.25C16.5 8.05109 16.421 7.86032 16.2803 7.71967C16.1397 7.57902 15.9489 7.5 15.75 7.5H9V4.5H19.5V15Z'
									fill='#616161'
								/>
							</svg>
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
