import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    activeChama: {},
    chamaActive: false,
    newChamaData: {},
    chamaFeaturesArray: [],
    initialmemberInvite: {},
    activeTab: {
        memberBalance: '1',
        statements: '1',
        memberDetails: '1',
    },
    activeMember: {},
    activeContributionType: {},
};

export const objSlice = createSlice({
    name: 'obj',
    initialState,
    reducers: {
        setActiveChama: (state, action) => {
            state.activeChama = action.payload;
            state.chamaActive = true;
        },
        handleNewChamaData: (state, action) => {
            state.newChamaData = { ...state.newChamaData, ...action.payload };
        },
        handleChamaFeaturesArray: (state, action) => {
            state.chamaFeaturesArray = action.payload;
        },
        handleActiveTab: (state, action) => {
            state.activeTab = { ...state.activeTab, ...action.payload };
        },
        handleInitialmemberInvite: (state, action) => {
            state.initialmemberInvite = action.payload;
        },
        handleActiveContributionType: (state, action) => {
            state.activeContributionType = action.payload;
        },
        setActiveMember: (state, action) => {
            state.activeMember = action.payload;
        },
        clearActiveTabs: (state) => {
            state.activeTab = initialState.activeTab;
            state.activeMember = {};
        },
        clearObj: () => {
            return { ...initialState };
        },
    },
});

export default objSlice.reducer;
export const { setActiveChama, clearObj, handleNewChamaData, handleChamaFeaturesArray, handleActiveTab, handleInitialmemberInvite, setActiveMember, clearActiveTabs, handleActiveContributionType } =
    objSlice.actions;
