import { useEffect } from 'react';
import { DatePicker, Form, Input, Radio, Select, Tooltip } from 'antd';
import info from '../../../assets/svg/Info.svg';
import info32 from '../../../assets/svg/Info32.svg';

export default function MerryGoRoundStep({ handleNext, form, handleBack }) {

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<>
			<div className='flex flex-col w-full'>
				<Form.Item label='Merry-go-round cycle length'>
					<div className='grid grid-cols-1 lg:grid-cols-2 gap-[2rem]'>
						<Form.Item
							label='From'
							name='mgrFrom'
							rules={[
								{
									required: true,
									message: 'Field is required',
								},
							]}>
							<DatePicker
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='22'
										height='23'
										viewBox='0 0 22 23'
										fill='none'>
										<path
											d='M17.875 3.52881H4.125C3.7453 3.52881 3.4375 3.83661 3.4375 4.21631V17.9663C3.4375 18.346 3.7453 18.6538 4.125 18.6538H17.875C18.2547 18.6538 18.5625 18.346 18.5625 17.9663V4.21631C18.5625 3.83661 18.2547 3.52881 17.875 3.52881Z'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M15.125 2.15381V4.90381'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M6.875 2.15381V4.90381'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M3.4375 7.65381H18.5625'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								}
								className='input'
								placeholder=''
							/>
						</Form.Item>

						<Form.Item
							label='To'
							name='mgrTo'
							rules={[
								{
									required: true,
									message: 'Field is required',
								},
							]}>
							<DatePicker
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='22'
										height='23'
										viewBox='0 0 22 23'
										fill='none'>
										<path
											d='M17.875 3.52881H4.125C3.7453 3.52881 3.4375 3.83661 3.4375 4.21631V17.9663C3.4375 18.346 3.7453 18.6538 4.125 18.6538H17.875C18.2547 18.6538 18.5625 18.346 18.5625 17.9663V4.21631C18.5625 3.83661 18.2547 3.52881 17.875 3.52881Z'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M15.125 2.15381V4.90381'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M6.875 2.15381V4.90381'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M3.4375 7.65381H18.5625'
											stroke='#343330'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								}
								className='input'
								placeholder=''
							/>
						</Form.Item>
					</div>
				</Form.Item>

				<Form.Item
					label={
						<>
							<div className='w-full flex items-center gap-[.5rem]'>
								<span>Are members allowed to join mid-cycle?</span>
								<Tooltip
									arrow={false}
									// open={true}
									placement='rightTop'
									title={
										<>
											<div className='w-full flex flex-col p-[1rem] gap-[.5rem]'>
												<div className='flex items-start gap-[.5rem]'>
													<img src={info32} alt='info' />
													<span className='toolTip_header'>
														Allow members to join mid-cycle
													</span>
												</div>

												<span className='toolTip_text'>
													If allowed, members can join during the cycle. They
													will accrue arrears for the months they missed and
													will need to pay the minimum contribution for those
													months.
												</span>
											</div>
										</>
									}>
									<img src={info} alt='info' />
								</Tooltip>
							</div>
						</>
					}
					name='mgrAllowJoining'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Radio.Group value={null}>
						<Radio value={true}>Yes</Radio>
						<Radio value={false}>No</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					label={
						<>
							<div className='w-full flex items-center gap-[.5rem]'>
								<span>Maximum no. of seats per member</span>
								<Tooltip
									arrow={false}
									// open={true}
									placement='rightTop'
									title={
										<>
											<div className='w-full flex flex-col p-[1rem] gap-[.5rem]'>
												<div className='flex items-start gap-[.5rem]'>
													<img src={info32} alt='info' />
													<span className='toolTip_header'>No. of seats</span>
												</div>

												<span className='toolTip_text'>
													Each "seat" a member buys determines how many times
													they receive the full amount during the cycle.
													<br />
													<br />
													Seat: Each seat means one chance to get the full
													amount. Cost: Each seat costs the same as the minimum
													contribution (e.g., KES 1000). Example: If a member
													buys two seats for KES 2000, they’ll get their full
													amount twice in the cycle.
													<br />
													<br />
													The more seats a member has, the more times they get
													their full savings within a cycle.
												</span>
											</div>
										</>
									}>
									<img src={info} alt='info' />
								</Tooltip>
							</div>
						</>
					}
					name='custSeat'
					rules={[
						{
							required: true,
							message: 'Field is required',
						},
					]}>
					<Select
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						options={[1, 2, 3, 4, 5].map((item, index) => {
							return {
								label: (
									<div key={index}>
										<span>{item}</span>
									</div>
								),
								value: item,
							};
						})}
					/>
				</Form.Item>

				<Form.Item
					label={
						<>
							<div className='w-full flex items-center gap-[.5rem]'>
								<span>Minimum amount disbursed to savings collection</span>
								<Tooltip
									arrow={false}
									// open={true}
									placement='rightTop'
									title={
										<>
											<div className='w-full flex flex-col p-[1rem] gap-[.5rem]'>
												<div className='flex items-start gap-[.5rem]'>
													<img src={info32} alt='info' />
													<span className='toolTip_header'>
														Minimum amount disbursed to savings from a member’s
														contribution
													</span>
												</div>

												<span className='toolTip_text'>
													For example, if minimum amount is set to 20%, 80% will
													go to the merry-go-round collection and 20% will go to
													the group savings collection.
												</span>
											</div>
										</>
									}>
									<img src={info} alt='info' />
								</Tooltip>
							</div>
						</>
					}
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Radio.Group className='flex items-center gap-[1rem]' value={null}>
						<Form.Item
							label='Percentage (%)'
							name='mgrMinCollectionAmountPercentage'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Input prefix={<Radio value={true}></Radio>} className='input' />
						</Form.Item>

						<span className='label_2'>Or</span>

						<Form.Item
							label='Fixed amount (KES)'
							name='mgrMinCollectionAmountFixed'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Input prefix={<Radio value={true}></Radio>} className='input' />
						</Form.Item>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					label='Penalty accrued for late contribution'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<div className='flex flex-col w-full'>
						<Radio.Group className='flex items-center gap-[1rem]' value={null}>
							<Form.Item
								label='Percentage (%)'
								name='mgrPenaltyPercentage'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<Input
									prefix={<Radio value={true}></Radio>}
									className='input'
								/>
							</Form.Item>

							<Form.Item
								label='Frequency'
								name='mgrPenaltyPercentageFrequency'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<Select
									disabled
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='21'
											height='21'
											viewBox='0 0 21 21'
											fill='none'>
											<path
												d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
												fill='#212121'
											/>
										</svg>
									}
									options={[]}
								/>
							</Form.Item>
						</Radio.Group>

						<span className='label_2'>Or</span>

						<Radio.Group className='flex items-center gap-[1rem]' value={null}>
							<Form.Item
								label='Fixed amount (KES)'
								name='mgrPenaltyFixed'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<Input
									prefix={<Radio value={true}></Radio>}
									className='input'
								/>
							</Form.Item>

							<Form.Item
								label='Frequency'
								name='mgrPenaltyFixedFrequency'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<Select
									disabled
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='21'
											height='21'
											viewBox='0 0 21 21'
											fill='none'>
											<path
												d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
												fill='#212121'
											/>
										</svg>
									}
									options={[]}
								/>
							</Form.Item>
						</Radio.Group>
					</div>
				</Form.Item>

				<Form.Item
					name='mgrSettlementMode'
					label='Mode of settlement'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<div className='flex flex-col w-full'>
						<Radio.Group className='flex flex-col gap-[1rem]' value={null}>
							<div
								style={{
									borderRadius: '1.5rem',
									border: '1px solid var(--Ndovu-Black, #121212)',
									background: 'rgba(119, 255, 0, 0.08)',
								}}
								className='w-full flex flex-col gap-[1.25rem] py-[1.5rem] px-[1rem]'>
								<div className='w-full flex justify-between items-center'>
									<span className='label_2'>Schedule</span>
									<Radio value={true}></Radio>
								</div>
								<span className='paragraph_2'>
									Manually organize the order in which members will receive
									their full sum. You will be able to do this once members are
									added.
								</span>
							</div>
							<div
								style={{
									borderRadius: '1.5rem',
									border: '1px solid var(--Ndovu-Black, #121212)',
									background: '#fff',
								}}
								className='w-full flex flex-col gap-[1.25rem] py-[1.5rem] px-[1rem]'>
								<div className='w-full flex justify-between items-center'>
									<span className='label_2'>Shuffle</span>
									<Radio value={true}></Radio>
								</div>
								<span className='paragraph_2'>
									Use a system shuffle to determine the order in which members
									receive their full sum.
								</span>
							</div>
						</Radio.Group>
					</div>
				</Form.Item>

				<div className='py-[2.37rem] w-full flex justify-center items-center'>
					<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
						<button
							// disabled={saving}
							onClick={() => handleNext()}
							type='button'
							className='cstm-btn-2'>
							Next
						</button>

						<button
							onClick={() => handleBack()}
							type='button'
							className='cstm-btn'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
