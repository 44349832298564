import { useEffect } from 'react';
import ProTips from '../../components/ProTips';
import UserBanner from '../../components/UserBanner';
import ChamaList from './ChamaList';
import { useDispatch } from 'react-redux';
import { clearObj } from '../../features/obj/objSlice';
import PendingChamaList from './PendingChamaList';
import useFetch from '../../custom_hooks/useFetch';
import { applicationUrl } from '../../utils';
import { handlememberRole } from '../../features/auth/authSlice';

export default function MainHome() {
	const dispatch = useDispatch();
	const { data } = useFetch(
		`${applicationUrl().url}/api/v1/invite/myPendingInvite`
	);

	async function handleInit() {
		await dispatch(clearObj());
		await dispatch(handlememberRole(''))
	}

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
		handleInit();
	}, []);

	

	return (
		<>
			<div className='grid grid-cols-1 gap-[1.25rem]'>
				<UserBanner />
				<ProTips />
				{data?.length ? (
					<PendingChamaList pendingInvites={data} />
				) : null}

				<ChamaList />
			</div>
		</>
	);
}
