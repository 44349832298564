export const collectionIntervals = [
    {
        label: 'Daily',
        value: 'Daily',
    },
    {
        label: 'Weekly',
        value: 'Weekly',
    },
    {
        label: 'Bi-weekly (Every two weeks)',
        value: 'Biweekly',
    },
    {
        label: 'Monthly',
        value: 'Monthly',
    },
    {
        label: 'Quarterly',
        value: 'Quarterly',
    },
    {
        label: 'Every 6 months',
        value: 'Semi Annually',
    },
    {
        label: 'Annually',
        value: 'Annually',
    },
];

export const weeeklyContributions = [
    {
        label: 'Every Monday',
        value: 'Every Monday',
    },
    {
        label: 'Every Tuesday',
        value: 'Every Tuesday',
    },
    {
        label: 'Every Wednesday',
        value: 'Every Wednesday',
    },
    {
        label: 'Every Thursday',
        value: 'Every Thursday',
    },
    {
        label: 'Every Friday',
        value: 'Every Friday',
    },
    {
        label: 'Every Saturday',
        value: 'Every Saturday',
    },
    {
        label: 'Every Sunday',
        value: 'Every Sunday',
    },
];

export const biweeeklyContributions = [
    {
        label: 'Every Other Monday',
        value: 'Every Other Monday',
    },
    {
        label: 'Every Other Tuesday',
        value: 'Every Other Tuesday',
    },
    {
        label: 'Every Other Wednesday',
        value: 'Every Other Wednesday',
    },
    {
        label: 'Every Other Thursday',
        value: 'Every Other Thursday',
    },
    {
        label: 'Every Other Friday',
        value: 'Every Other Friday',
    },
    {
        label: 'Every Other Saturday',
        value: 'Every Other Saturday',
    },
    {
        label: 'Every Other Sunday',
        value: 'Every Other Sunday',
    },
];

export const roleTypes = [
    {
        label: 'Chairman',
        value: 'CHAIRMAN',
    },
    {
        label: 'Treasurer',
        value: 'TREASURER',
    },
    {
        label: 'Secretary',
        value: 'SECRETARY',
    },
];

export const reportType = [
    {
        label: 'Member',
        value: 'INDIVIDUAL',
    },
    {
        label: 'Chama Group',
        value: 'ORGANIZATION',
    },
];

export const booleanType = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
];

export const groupMandates = [
    {
        label: 'At least 2 signatories',
        value: 2,
    },
    {
        label: 'At least 3 signatories',
        value: 3,
    },
];

export const emptyChartArray = [
    { time: 'Mon', collections: 0, arrears: 0 },
    { time: 'Tue', collections: 0, arrears: 0 },
    { time: 'Wed', collections: 0, arrears: 0 },
    { time: 'Thu', collections: 0, arrears: 0 },
    { time: 'Fri', collections: 0, arrears: 0 },
    { time: 'Sat', collections: 0, arrears: 0 },
    { time: 'Sun', collections: 0, arrears: 0 },
];

export const transactionType = [
    {
        label: 'MPESA',
        value: 'MPESA',
    },
    {
        label: 'BANK',
        value: 'BANK',
    },
];

export const debitNoteType = [
    {
        label: 'Arrear',
        value: 'Arrear',
    },
    {
        label: 'Penalty',
        value: 'Penalty',
    },
    {
        label: 'Loan',
        value: 'Loan',
    },
    {
        label: 'Other',
        value: 'Other',
    },
];

export const membersRoleColors = [
    {
        label: 'CHAIRMAN',
        value: '#E7F4E7',
    },
    {
        label: 'TREASURER',
        value: '#F1E7F3',
    },
    {
        label: 'SECRETARY',
        value: 'rgba(147, 189, 205, 0.22)',
    },
    {
        label: 'ADMIN',
        value: '#FDFFE9',
    },
    {
        label: 'MEMBER',
        value: '#F4F4F4',
    },
];

export const debitNoteTypeColor = [
    {
        label: 'Arrear',
        value: '#E7F4E7',
    },
    {
        label: 'ARREARS',
        value: '#E7F4E7',
    },
    {
        label: 'Penalty',
        value: 'rgba(255, 227, 230, 0.69)',
    },
    {
        label: 'Loan',
        value: '#EEFCFF',
    },
    {
        label: 'Other',
        value: '#FDFFE9',
    },
];

export const statusColor = [
    {
        label: 'PENDING',
        value: '#E2E9EE',
    },
    {
        label: 'PENDING_OTP_VALIDATION',
        value: '#E2E9EE',
    },
    {
        label: 'PENDING_APPROVAL',
        value: '#FFA17F',
    },

    {
        label: 'ONGOING',
        value: 'rgba(147, 205, 147, 0.22)',
    },
    {
        label: 'PAID',
        value: 'rgba(147, 205, 147, 0.5)',
    },
    {
        label: 'SUCCESS',
        value: 'rgba(147, 205, 147, 0.5)',
    },

    {
        label: 'FAILED',
        value: 'rgba(255, 76, 94, 0.69)',
    },
];

export const memberMenu = [
    {
        key: '2',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path
                    d="M16.0109 11.047H15.9626L15.9411 11.0902L12.9895 16.9949C12.9895 16.9949 12.9895 16.9949 12.9895 16.9949C12.944 17.0858 12.8742 17.1622 12.7877 17.2155C12.7013 17.2689 12.6017 17.2971 12.5001 17.297H12.5H12.4707C12.3653 17.2915 12.2638 17.2555 12.1783 17.1935C12.0924 17.1311 12.0265 17.045 11.9886 16.9458L7.52223 5.21923L7.45558 5.04425L7.3781 5.21471L4.87263 10.7264L4.87263 10.7264C4.82919 10.822 4.75917 10.903 4.67093 10.9599C4.58269 11.0167 4.47996 11.047 4.375 11.047C4.37499 11.047 4.37498 11.047 4.37497 11.047H1.875C1.72996 11.047 1.59086 10.9894 1.4883 10.8869C1.38574 10.7843 1.32812 10.6452 1.32812 10.5002C1.32812 10.3551 1.38574 10.216 1.4883 10.1135C1.59086 10.0109 1.72996 9.95329 1.875 9.95329H3.97266H4.02296L4.04378 9.90749L7.00237 3.3989L7.00238 3.39887C7.04701 3.3006 7.11973 3.21773 7.21137 3.1607C7.303 3.10367 7.40947 3.07503 7.51735 3.07839C7.62524 3.08175 7.72971 3.11696 7.81762 3.17959C7.90553 3.24222 7.97294 3.32945 8.01137 3.43032L12.5043 15.2272L12.5677 15.3935L12.6472 15.2343L15.1355 10.257L15.1356 10.2568C15.1809 10.1657 15.2507 10.089 15.3371 10.0354C15.4236 9.98178 15.5233 9.95334 15.625 9.95329H18.125C18.27 9.95329 18.4091 10.0109 18.5117 10.1135C18.6143 10.216 18.6719 10.3551 18.6719 10.5002C18.6719 10.6452 18.6143 10.7843 18.5117 10.8869C18.4091 10.9894 18.27 11.047 18.125 11.047H16.0109Z"
                    fill="#FFCD47"
                    stroke="#FFCD47"
                    strokeWidth="0.15625"
                />
            </svg>
        ),
        label: 'Group activity',
        children: [
            {
                label: 'Overview',
                key: '/group/overview',
            },
            {
                label: 'Contribution types',
                key: '/contribution/types',
            },
            {
                label: 'Members',
                key: '/members/overview',
            },
        ],
    },
    {
        key: '4',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clipPath="url(#clip0_863_35748)">
                    <path
                        d="M13.25 4.95076C13.25 6.71849 11.7949 8.15152 10 8.15152C8.20507 8.15152 6.75 6.71849 6.75 4.95076C6.75 3.18303 8.20507 1.75 10 1.75C11.7949 1.75 13.25 3.18303 13.25 4.95076Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.75 16.3996C3.75 13.748 5.93261 11.5985 8.625 11.5985H11.375C14.0674 11.5985 16.25 13.748 16.25 16.3996C16.25 17.2835 15.5225 18 14.625 18H5.375C4.47754 18 3.75 17.2835 3.75 16.3996Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_863_35748">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        label: 'Your activity',
        children: [
            {
                label: 'Overview',
                key: '/activity/overview',
            },
            {
                label: 'Statements',
                key: '/activity/statements',
            },
            {
                label: 'Payment',
                key: '/activity/payment',
            },
            {
                label: 'Reimbursement',
                key: '/activity/reimbursement',
            },
        ],
    },
];

export const validMemberPaths = [
    '/home',
    '/join-chama',
    '/group/overview',
    '/contribution/types',
    '/members/overview',
    '/activity/overview',
    '/activity/statements',
    '/activity/payment',
    '/activity/reimbursement',
];

export const mainMenu = [
    {
        key: '2',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path
                    d="M16.0109 11.047H15.9626L15.9411 11.0902L12.9895 16.9949C12.9895 16.9949 12.9895 16.9949 12.9895 16.9949C12.944 17.0858 12.8742 17.1622 12.7877 17.2155C12.7013 17.2689 12.6017 17.2971 12.5001 17.297H12.5H12.4707C12.3653 17.2915 12.2638 17.2555 12.1783 17.1935C12.0924 17.1311 12.0265 17.045 11.9886 16.9458L7.52223 5.21923L7.45558 5.04425L7.3781 5.21471L4.87263 10.7264L4.87263 10.7264C4.82919 10.822 4.75917 10.903 4.67093 10.9599C4.58269 11.0167 4.47996 11.047 4.375 11.047C4.37499 11.047 4.37498 11.047 4.37497 11.047H1.875C1.72996 11.047 1.59086 10.9894 1.4883 10.8869C1.38574 10.7843 1.32812 10.6452 1.32812 10.5002C1.32812 10.3551 1.38574 10.216 1.4883 10.1135C1.59086 10.0109 1.72996 9.95329 1.875 9.95329H3.97266H4.02296L4.04378 9.90749L7.00237 3.3989L7.00238 3.39887C7.04701 3.3006 7.11973 3.21773 7.21137 3.1607C7.303 3.10367 7.40947 3.07503 7.51735 3.07839C7.62524 3.08175 7.72971 3.11696 7.81762 3.17959C7.90553 3.24222 7.97294 3.32945 8.01137 3.43032L12.5043 15.2272L12.5677 15.3935L12.6472 15.2343L15.1355 10.257L15.1356 10.2568C15.1809 10.1657 15.2507 10.089 15.3371 10.0354C15.4236 9.98178 15.5233 9.95334 15.625 9.95329H18.125C18.27 9.95329 18.4091 10.0109 18.5117 10.1135C18.6143 10.216 18.6719 10.3551 18.6719 10.5002C18.6719 10.6452 18.6143 10.7843 18.5117 10.8869C18.4091 10.9894 18.27 11.047 18.125 11.047H16.0109Z"
                    fill="#FFCD47"
                    stroke="#FFCD47"
                    strokeWidth="0.15625"
                />
            </svg>
        ),
        label: 'Group activity',
        children: [
            {
                label: 'Overview',
                key: '/group/overview',
            },
            {
                label: 'Contribution types',
                key: '/contribution/types',
            },
            {
                label: 'Debit notes',
                key: '/debit/notes',
            },
            {
                label: 'Accounts summary',
                key: '/account/summary',
            },
            {
                key: '9',
                label: 'Funds transfer',
                children: [
                    {
                        label: 'Settlement',
                        key: '/funds-transfer/settlement',
                    },
                    {
                        label: 'Disbursement',
                        key: '/funds-transfer/disbursement',
                    },
                    {
                        label: 'Accounts transfer',
                        key: '/funds-transfer/accounts-transfer',
                    },
                ],
            },
            {
                key: '6',
                label: 'Members management',
                children: [
                    {
                        label: 'Overview',
                        key: '/members/overview',
                    },
                    {
                        label: 'Opening balance',
                        key: '/members/balance',
                    },
                    // {
                    //     label: 'Outstanding balance',
                    //     key: '/members/outstanding-balance',
                    // },
                ],
            },
            {
                label: 'Statements',
                key: '7',
                children: [
                    {
                        label: 'Statements summary',
                        key: '/statements/summary',
                    },
                    {
                        label: 'All statements',
                        key: '/group-activity/statements',
                    },
                    {
                        label: 'Contribution statements',
                        key: '/group-activity/statements/contributions',
                    },
                    {
                        label: 'Arrears statements',
                        key: '/group-activity/statements/arrears',
                    },
                ],
            },
            {
                key: '8',
                label: 'Approvals',
                children: [
                    {
                        label: 'Members approval',
                        key: '/approvals/members',
                    },
                    {
                        label: 'Settlement approvals',
                        key: '/approvals/settlement',
                    },
                    {
                        label: 'Disbursement approvals',
                        key: '/approvals/disbursements',
                    },
                    {
                        label: 'Reimbursement approvals',
                        key: '/approvals/reimbursement',
                    },
                    {
                        label: 'Account transfer approvals',
                        key: '/approvals/account-transfer',
                    },
                    {
                        label: 'Opening balance approvals',
                        key: '/approvals/opening-balance',
                    },
                ],
            },
            {
                label: 'Loans',
                key: '3',
                disabled: true,
                children: [
                    {
                        label: 'Loan summary',
                        key: '/loans/summary',
                    },
                    {
                        label: 'Loan requests',
                        key: '/loans/requests',
                    },
                ],
            },
        ],
    },
    {
        key: '4',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <g clipPath="url(#clip0_863_35748)">
                    <path
                        d="M13.25 4.95076C13.25 6.71849 11.7949 8.15152 10 8.15152C8.20507 8.15152 6.75 6.71849 6.75 4.95076C6.75 3.18303 8.20507 1.75 10 1.75C11.7949 1.75 13.25 3.18303 13.25 4.95076Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.75 16.3996C3.75 13.748 5.93261 11.5985 8.625 11.5985H11.375C14.0674 11.5985 16.25 13.748 16.25 16.3996C16.25 17.2835 15.5225 18 14.625 18H5.375C4.47754 18 3.75 17.2835 3.75 16.3996Z"
                        stroke="#FFCD47"
                        strokeWidth="1.50926"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_863_35748">
                        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        label: 'Your activity',
        children: [
            {
                label: 'Overview',
                key: '/activity/overview',
            },
            {
                label: 'Statements',
                key: '/activity/statements',
            },
            {
                label: 'Payment',
                key: '/activity/payment',
            },
            {
                label: 'Reimbursement',
                key: '/activity/reimbursement',
            },
            {
                label: 'Loans',
                key: '/activity/loans',
                disabled: true,
            },
        ],
    },
];
