import moment from 'moment';
import { getRandomColor } from '../../../utils';

export default function PendingInviteCard({ item }) {
	return (
		<>
			<div
				style={{
					border: '1px solid var(--Tile-stroke, #212121)',
				}}
				className='w-[25.0625rem] flex flex-col h-full px-[.625rem] py-[1.5rem] rounded-[1.5rem] min-h-[7rem]'>
				<div className='flex items-start w-full gap-[.75rem]'>
					<img
						src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item?.mbiCustName)}&background=${getRandomColor()}&color=fff`}
						className='w-[3.25rem] h-[3.25rem] rounded-full object-cover'
						alt='Chama icon'
					/>

					<div className='flex flex-col'>
						<span className='heading_4'>
							{item?.mbiCustName}
						</span>
						<span className='chama_card_date'>
							Request made on {moment(item?.mbiInviteDate).format('Do MMM YYYY')}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
