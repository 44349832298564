import { Tabs } from 'antd';
import MemberInfoCard from './cards/MemberInfoCard';
import MemberOverview from './tabs/MemberOverviewTab';
import MemberStatementTab from './tabs/MemberStatementTab';
import MemberLoansTab from './tabs/MemberLoansTab';
import { useDispatch, useSelector } from 'react-redux';
import { handleActiveTab } from '../../features/obj/objSlice';
import { useEffect } from 'react';

export default function MemberDetails() {
	const dispatch = useDispatch()
	
	const { activeTab } = useSelector((state) => state.obj);

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const items = [
		{
			key: '1',
			label: 'Overview',
			children: <MemberOverview />,
		},
		{
			key: '2',
			label: 'Statements',
			children: <MemberStatementTab />,
		},
		{
			key: '3',
			label: 'Loans',
			children: <MemberLoansTab />,
			disabled: true,
		},
		{
			key: '4',
			label: 'Permissions',
			children: 'Permissions',
			disabled: true,
		},
		{
			key: '5',
			label: 'Contact info',
			children: 'Contact info',
			disabled: true,
		},
	];

	return (
		<>
			<MemberInfoCard />

			<div className='flex flex-col pt-[2.5rem] bg-white w-full'>
				<Tabs
					onChange={(key) =>
						dispatch(
							handleActiveTab({
								memberDetails: key,
							})
						)
					}
					activeKey={activeTab?.memberDetails}
					className='underlined_tab'
					items={items}
				/>
			</div>
		</>
	);
}
