export default function StatementSummaryCard({
	hasFooter,
	header,
	amount,
	background,
	footer,
}) {
	return (
		<>
			<div
				style={{
					background: background,
					border: '1px solid #E2E9EE',
					padding: '1.75rem 0.8125rem 1.75rem 1.5rem',
				}}
				className='min-w-[30rem] flex flex-col justify-center items-start h-[13.625rem] flex-shrink-0 flex-grow rounded-[1rem]'>
				<div className='w-full flex items-center gap-[1rem]'>
					<span className='label_2 text-[#121212] text-center'>
						{header}
					</span>
				</div>
				<span className='heading_2'>{amount}</span>
				{hasFooter ? <span className='paragraph_2'>{footer}</span> : null}
			</div>
		</>
	);
}
