import BreadCrumb from '../../layout/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { applicationUrl, capitalize, formatMoney } from '../../utils';
import StatementSummaryCard from './cards/StatementSummaryCard';
import Search from '../../components/Search';
import StatementFilter from './StatementFilter';
import { useNavigate } from 'react-router-dom';
import MembersStatementSummaryTable from './tables/MembersStatementSummaryTable';
import { clearActiveTabs, handleActiveTab } from '../../features/obj/objSlice';
import useFetch from '../../custom_hooks/useFetch';
import CardLoading from '../../components/CardLoading';
import { useEffect } from 'react';
import useModalToggle from '../../custom_hooks/useModalToggle';
import ExportToPDFSummaryModal from '../../components/ExportToPdfSummaryModal';

export default function StatementsSummary() {
    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/group-summary`);
    const { open, handleCancel, handleOpen } = useModalToggle();

    const { user } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { activeChama } = useSelector((state) => state.obj);
    

    async function handleViewAllStatements() {
        await dispatch(
            handleActiveTab({
                statements: '1',
            })
        );
        await navigate('/group-activity/statements');
    }

    useEffect(() => {
        dispatch(clearActiveTabs());
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const breadList = [
        {
            title: (
                <div className="flex items-center gap-[.25rem]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_627_13942)">
                            <path
                                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                                fill="black"
                                fillOpacity="0.45"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_627_13942">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Home</span>
                </div>
            ),
            href: '/#/home',
        },
        {
            title: capitalize(activeChama?.custName),
        },
    ];

    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem] pb-[5rem]">
                <div className="justify-between items-center white_card">
                    <BreadCrumb breadList={breadList} header={'Group activity | Statements summary'} info={'Here’s an overview of group transactions'} />
                </div>

                {isLoading ? (
                    <CardLoading />
                ) : (
                    <div className="white_card flex-col">
                        <span className="heading_4">Statements summary</span>
                        <div className="w-full flex flex-col lg:flex-row items-center flex-wrap gap-[1rem] lg:gap-[2.5rem] mt-[1.69rem]">
                            <StatementSummaryCard
                                hasFooter={true}
                                footer={'An aggregated amount of all payments expected from members'}
                                background={'#F3EBFF'}
                                header={'Group expected collection'}
                                amount={formatMoney(data?.totalDebit, 'KES')}
                            />
                            <StatementSummaryCard
                                hasFooter={true}
                                footer={'An aggregated amount of all contributions made by group members'}
                                background={'rgba(147, 205, 147, 0.14)'}
                                header={' Group received collection'}
                                amount={formatMoney(Math.abs(data?.totalCredit), 'KES')}
                            />
                            <StatementSummaryCard
                                hasFooter={true}
                                footer={'An aggregated amount of all pending arrears'}
                                background={'#FDFFE9'}
                                header={'Group arrears'}
                                amount={formatMoney(Math.abs(data?.totalArrears), 'KES')}
                            />
                        </div>
                    </div>
                )}

                <div className="white_card flex-col">
                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        <div className="flex justify-between items-center">
                            <span className="heading_4">Members summary statements</span>
                            <button type="button" onClick={handleViewAllStatements} className="flex items-center gap-[1rem]">
                                <span className="view_more_text">View more</span>
                                <div
                                    style={{
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                                        background: '#fff',
                                    }}
                                    className="w-[1.5rem] h-[1.5rem] p-[.25rem] flex justify-center items-center rounded-full"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M3.85336 13.354C3.8069 13.4005 3.75175 13.4373 3.69106 13.4625C3.63036 13.4876 3.5653 13.5006 3.49961 13.5006C3.43391 13.5006 3.36886 13.4876 3.30816 13.4625C3.24746 13.4373 3.19231 13.4005 3.14586 13.354C3.0994 13.3076 3.06255 13.2524 3.03741 13.1917C3.01227 13.131 2.99933 13.066 2.99933 13.0003C2.99933 12.9346 3.01227 12.8695 3.03741 12.8088C3.06255 12.7481 3.0994 12.693 3.14586 12.6465L7.79273 8.00028L3.14586 3.35403C3.05204 3.26021 2.99933 3.13296 2.99933 3.00028C2.99933 2.8676 3.05204 2.74035 3.14586 2.64653C3.23968 2.55271 3.36693 2.5 3.49961 2.5C3.63229 2.5 3.75954 2.55271 3.85336 2.64653L8.85336 7.64653C8.89985 7.69296 8.93672 7.74811 8.96189 7.80881C8.98705 7.86951 9 7.93457 9 8.00028C9 8.06599 8.98705 8.13105 8.96189 8.19175C8.93672 8.25245 8.89985 8.30759 8.85336 8.35403L3.85336 13.354ZM8.14586 3.35403L12.7927 8.00028L8.14586 12.6465C8.0994 12.693 8.06255 12.7481 8.03741 12.8088C8.01227 12.8695 7.99933 12.9346 7.99933 13.0003C7.99933 13.066 8.01227 13.131 8.03741 13.1917C8.06255 13.2524 8.0994 13.3076 8.14586 13.354C8.19231 13.4005 8.24746 13.4373 8.30816 13.4625C8.36885 13.4876 8.43391 13.5006 8.49961 13.5006C8.5653 13.5006 8.63036 13.4876 8.69105 13.4625C8.75175 13.4373 8.8069 13.4005 8.85336 13.354L13.8534 8.35403C13.8998 8.30759 13.9367 8.25245 13.9619 8.19175C13.987 8.13105 14 8.06599 14 8.00028C14 7.93457 13.987 7.86951 13.9619 7.80881C13.9367 7.74811 13.8998 7.69296 13.8534 7.64653L8.85336 2.64653C8.75954 2.55271 8.63229 2.5 8.49961 2.5C8.36692 2.5 8.23968 2.55271 8.14586 2.64653C8.05204 2.74035 7.99933 2.8676 7.99933 3.00028C7.99933 3.13296 8.05204 3.26021 8.14586 3.35403Z"
                                            fill="#343330"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div className="flex items-center gap-[1rem] w-full">
                        <div className="flex w-full justify-between items-center">
                            <div className="flex items-center gap-[1rem] w-full">
                                <div className="w-[13.625rem] hidden lg:flex">
                                    <Search text={'Search'} />
                                </div>

                                <div className="w-[12.5rem] hidden lg:flex">
                                    <StatementFilter />
                                </div>
                            </div>
                            <div className="flex w-auto">
                                <button onClick={handleOpen} type="button" className="cstm-btn-2 !gap-[.25rem]">
                                    <svg className="flex flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path
                                            d="M11.65 18.45L15.35 11.25H12.5V6.5L8.675 13.75H11.65V18.45ZM12 22C10.6833 22 9.446 21.75 8.288 21.25C7.12933 20.75 6.125 20.075 5.275 19.225C4.425 18.375 3.75 17.3707 3.25 16.212C2.75 15.054 2.5 13.8167 2.5 12.5C2.5 11.1833 2.75 9.94567 3.25 8.787C3.75 7.629 4.425 6.625 5.275 5.775C6.125 4.925 7.12933 4.25 8.288 3.75C9.446 3.25 10.6833 3 12 3C13.3167 3 14.5543 3.25 15.713 3.75C16.871 4.25 17.875 4.925 18.725 5.775C19.575 6.625 20.25 7.629 20.75 8.787C21.25 9.94567 21.5 11.1833 21.5 12.5C21.5 13.8167 21.25 15.054 20.75 16.212C20.25 17.3707 19.575 18.375 18.725 19.225C17.875 20.075 16.871 20.75 15.713 21.25C14.5543 21.75 13.3167 22 12 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z"
                                            fill="#FFCD47"
                                        />
                                    </svg>
                                    <span className="flex flex-shrink-0">Export to pdf</span>
                                </button>
                            </div>
                        </div>
                        </div>

                        <MembersStatementSummaryTable />
                    </div>
                </div>
            </div>

            <ExportToPDFSummaryModal open={open} handleCancel={handleCancel} P_STATEMENT={'ACCOUNT_STATEMENT_DEBIT_NOTES_SUMMERY'} rptId={5} P_USER={user?.userName} />
        </>
    );
}
