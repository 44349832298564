import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import TableLoading from '../../../components/TableLoading';
import useFetch from '../../../custom_hooks/useFetch';
import { applicationUrl, getRandomColor } from '../../../utils';

export default function MemberPendingInviteTable() {
    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/invite/status/SENT`);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'mbiInviteDate',
            render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'mbiName',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`} className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover" alt="avatar" />
                        <span>{item}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'National ID',
            dataIndex: 'mbiNationalId',
        },
        {
            title: 'Phone',
            dataIndex: 'mbiPhone',
        },
        {
            title: 'Email',
            dataIndex: 'mbiEmail',
        },
    ];

    return (
        <div>
            <Table
                rowKey="mbiId"
                className="mt-[1.5rem] !w-full"
                pagination={false}
                columns={columns}
                dataSource={data}
                scroll={{
                    x: 1200,
                }}
            />
        </div>
    );
}
