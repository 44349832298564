import { useEffect, useState } from 'react';
import { handleLogout } from '../instance';

export default function useFetch(url) {
	const [isLoading, setisLoading] = useState(false);
	const [isError, setisError] = useState('');
	const [data, setdata] = useState([]);

	const token = localStorage.getItem('token');

	useEffect(() => {
		const fetchData = async () => {
			await setisLoading(true);
			try {
				const res = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});
				try {
					if (!res.ok) {
						if (res.status === 403) {
							handleLogout();
						}
						setisError('');
						setisLoading(false);
						return;
					}

					const response = await res?.json();
					setdata(response?.data?.result);
					setisError('');
				} catch (error) {
					setisError('');
				}
			} catch (error) {
				setisError('');
			}

			await setisLoading(false);
		};

		if (token) {
			fetchData();
		}
	}, [url, token]);
	return { isLoading, isError, data };
}
