import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import SettleMentAccountMpesaSubStep from './sub_steps/SettleMentMpesaAccountSubStep';
import SettleMentAccountBankSubStep from './sub_steps/SettleMentBamkAccountSubStep';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettlementAccounts } from '../../../features/fetch/fetchSlice';

export default function SettleMentAccountStep({ form, handleBack, handleReview }) {
    const dispatch = useDispatch();

    const { newChamaData } = useSelector((state) => state.obj);
    const { settlementAccounts } = useSelector((state) => state.fetch);

    async function handleFetch() {
        await dispatch(fetchSettlementAccounts(newChamaData?.custId));
    }

    const [add, setadd] = useState(false);

    const items = [
        {
            key: '1',
            label: 'M-PESA',
            children: <SettleMentAccountMpesaSubStep handleFetch={handleFetch} handleReview={handleReview} handleBack={handleBack} form={form} />,
        },
        {
            key: '2',
            label: 'Bank account',
            children: <SettleMentAccountBankSubStep handleFetch={handleFetch} handleReview={handleReview} handleBack={handleBack} form={form} />,
        },
    ];

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
        handleFetch();
    }, []);

    return (
        <>
            <div className="flex flex-col w-full justify-center text-center">
                <span className="label_2">Account details</span>
                <span className="paragraph_2">
                    Add an account to settle your group funds to. <br />
                    You may add more than one account.
                </span>

                {add || settlementAccounts?.length ? (
                    <>
                        <div className="flex flex-col w-full mt-[.62rem] bg-white p-[.5rem] lg:p-[2.5rem]">
                            <Tabs className="underlined_tab tab_orange" defaultActiveKey="1" items={items} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="py-[2.37rem] w-full flex justify-center items-center">
                            <div className="w-fit flex flex-col gap-[.5rem]">
                                <button className='mb-[1rem]' onClick={() => handleReview()} type="button">
                                    <span className='label_2 '>
                                        You can also <span className="underline">skip</span> this step
                                    </span>
                                </button>

                                <button
                                    onClick={() => setadd(true)}
                                    type="button"
                                    className="cstm-btn-2"
                                >
                                    Add
                                </button>

                                <button onClick={() => handleBack()} type="button" className="cstm-btn">
                                    Previous
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
