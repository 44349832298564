import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import userImg from '../assets/svg/user.svg';
import moment from 'moment';
import { handleOpenKeys, handleSelectedKeys, toggleCollapse } from '../features/setup/setupSlice';
import { getRandomColor } from '../utils';
import { mainMenu, memberMenu } from '../data';

const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
        items2.forEach((item) => {
            if (item.key) {
                key[item.key] = level;
            }
            if (item.children) {
                func(item.children, level + 1);
            }
        });
    };
    func(items1);
    return key;
};

export default function SideBarOpen({ isMobile }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { stateOpenKeys, stateSelectedKeys } = useSelector((state) => state.setup);
    const { user, memberRole } = useSelector((state) => state.auth);
    const { chamaActive, activeChama } = useSelector((state) => state.obj);

    const handleNavigation = async (e) => {
        await dispatch(handleSelectedKeys([e.key]));
        if (isMobile) await dispatch(toggleCollapse());
        await navigate(e.key);
    };

    const handleOpenChange = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys.filter((key) => key !== currentOpenKey).findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
            dispatch(handleOpenKeys(openKeys.filter((_, index) => index !== repeatIndex).filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])));
            dispatch(handleSelectedKeys(openKeys.filter((_, index) => index !== repeatIndex).filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])));
        } else {
            dispatch(handleOpenKeys(openKeys));
            dispatch(handleSelectedKeys(openKeys));
        }
    };

    const items = chamaActive
        ? memberRole === 'MEMBER'
            ? memberMenu
            : mainMenu
        : [
              {
                  key: '1',
                  icon: <img src={userImg} alt="user" />,
                  label: 'Home',
                  children: [
                      {
                          label: 'Chama',
                          key: '/home',
                      },
                      {
                          label: 'Personal investemet',
                          key: '/personal/investment',
                          disabled: true,
                      },
                  ],
              },
          ];

    const levelKeys = getLevelKeys(items);

    useEffect(() => {}, [chamaActive, activeChama, stateOpenKeys, stateSelectedKeys, memberRole]);

    return (
        <>
            <div
                style={{
                    borderRadius: '0.875rem 0.875rem 0rem 0rem',
                }}
                className="flex flex-col h-[100vh] overflow-y-auto flex-shrink-0 w-[15.75rem] bg-white"
            >
                {chamaActive ? (
                    <>
                        <div className="w-full max-w-sm bg-mainBg rounded-lg">
                            <div className="card">
                                <div className="cover-photo">
                                    <Link to="/home" className="absolute top-[1rem] left-[1.06rem]">
                                        <div className="bg-white flex justify-center items-center p-[.25rem] w-[2rem] h-[2rem] rounded-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
                                                <path
                                                    d="M13.7069 6.79266L8.70688 1.79266C8.51936 1.60527 8.26511 1.5 8.00001 1.5C7.7349 1.5 7.48065 1.60527 7.29313 1.79266L2.29313 6.79266C2.19982 6.88528 2.12586 6.99552 2.07553 7.11698C2.02521 7.23844 1.99954 7.36869 2.00001 7.50016L2.00001 13.5002C2.00001 13.6328 2.05268 13.7599 2.14645 13.8537C2.24022 13.9475 2.3674 14.0002 2.50001 14.0002L6.50001 14.0002C6.63261 14.0002 6.75979 13.9475 6.85356 13.8537C6.94733 13.7599 7.00001 13.6328 7.00001 13.5002L7.00001 10.0002H9.00001L9.00001 13.5002C9.00001 13.6328 9.05268 13.7599 9.14645 13.8537C9.24022 13.9475 9.3674 14.0002 9.50001 14.0002H13.5C13.6326 14.0002 13.7598 13.9475 13.8536 13.8537C13.9473 13.7599 14 13.6328 14 13.5002V7.50016C14.0005 7.36869 13.9748 7.23844 13.9245 7.11698C13.8742 6.99552 13.8002 6.88528 13.7069 6.79266ZM13 13.0002H10V9.50016C10 9.36755 9.94733 9.24038 9.85356 9.14661C9.75979 9.05284 9.63261 9.00016 9.50001 9.00016L6.50001 9.00016C6.3674 9.00016 6.24022 9.05284 6.14645 9.14661C6.05268 9.24038 6.00001 9.36755 6.00001 9.50016L6.00001 13.0002H3.00001L3.00001 7.50016L8.00001 2.50016L13 7.50016V13.0002Z"
                                                    fill="#343330"
                                                />
                                            </svg>
                                        </div>
                                    </Link>
                                    <img
                                        loading="lazy"
                                        style={{
                                            zIndex: '1000',
                                        }}
                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(activeChama?.custName)}&background=${getRandomColor()}&color=fff`}
                                        alt="chamaImg"
                                        className="profile z-40 rounded"
                                    />
                                </div>

                                <div className="flex flex-col items-center pb-[2.19rem] px-[.25rem]">
                                    <span className="heading_5 mt-[19%]">{activeChama?.custName}</span>
                                    <span className="profile_email mt-[.81rem] !text-[#98A2B3]">{`Joined ${moment(activeChama?.joinedDate).format('Do MMM YYYY')}`}</span>

                                    <div
                                        style={{
                                            padding: '0.375rem 0rem 0.5625rem 0rem',
                                        }}
                                        className="rounded-[2.5rem] bg-[#F5F5F5] w-[8.25rem] flex justify-center items-center mt-[1.25rem]"
                                    >
                                        <span className="label_4">{activeChama?.custMemberCount} members</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="w-full max-w-sm bg-mainBg rounded-lg">
                            <div className="card">
                                <div className="cover-photo">
                                    {/* <UserOutlined className="profile-icon" /> */}
                                    <img
                                        loading="lazy"
                                        style={{
                                            zIndex: '1000',
                                        }}
                                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user?.userName)}&background=${getRandomColor()}&color=fff`}
                                        alt="userImg"
                                        className="profile z-40 rounded"
                                    />
                                </div>

                                <div className="flex flex-col items-center pb-[2.19rem]">
                                    <span className="heading_5 mt-[19%]">{user?.userName}</span>
                                    <span className="profile_email mt-[1.44rem]">{user?.email}</span>
                                    <span className="paragraph_2 mt-[.94rem]">+{user?.phone}</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <Menu
                    onClick={handleNavigation}
                    style={{
                        width: '100%',
                    }}
                    mode="inline"
                    items={items}
                    theme="light"
                    openKeys={stateOpenKeys}
                    defaultSelectedKeys={['1', '2']}
                    selectedKeys={stateSelectedKeys}
                    onOpenChange={handleOpenChange}
                />
            </div>
        </>
    );
}
