import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { handleLoginSession, handleUserRefreshToken, login, logout } from '../../features/auth/authSlice';
import { useEffect, useRef } from 'react';
import { customToast } from '../../utils';
import image10 from '../../assets/svg/ndovuLogo.svg';
import loginImg from '../../assets/svg/login.svg';

export default function Login() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authLoading, isActive } = useSelector((state) => state.auth);

    const formRef = useRef(null);
    const onFinish = async (data) => {
        const res = await dispatch(login(data));

        if (res?.payload?.success) {
            await customToast({
                content: 'Login successful',
                bdColor: 'success',
            });
            await localStorage.setItem('token', res?.payload?.access_token);
            await dispatch(handleUserRefreshToken(res?.payload?.refresh_token));
            await dispatch(handleLoginSession(true));
            await form.resetFields();
        } else {
            await customToast({
                content: 'Invalid credentials',
                bdColor: 'error',
                id: 26617238823872,
            });
        }
    };

    useEffect(() => {
        if (!isActive) {
            dispatch(logout());
        }
    }, []);

    if (isActive) {
        return <Navigate to="/onboarding" replace />;
    }

    return (
        <>
            <div className="flex w-full h-[100vh]">
                <div className="w-full h-full flex justify-center items-center p-3 lg:p-[8.87rem] bg-white">
                    <div className="flex flex-col w-full">
                        <div className="flex items-center w-full gap-[.25rem]">
                            <img src={image10} className="logo !w-fit h-full" alt="image2" />
                            <span className="heading_4">Chamify Africa.</span>
                        </div>

                        <span className="heading_1 mt-[2.12rem]">Log in</span>
                        <span className="paragraph_1 mt-[1.5rem]">Welcome. Enter your details to log in.</span>

                        <Form
                            layout="vertical"
                            ref={formRef}
                            name="control-ref"
                            onFinish={onFinish}
                            style={{
                                maxWidth: '100%',
                                marginTop: '3rem',
                                width: '26.25rem',
                            }}
                            form={form}
                        >
                            <Form.Item
                                className="table-form-item"
                                name="nationalId"
                                label="National ID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'National ID is required to login',
                                    },
                                ]}
                            >
                                <Input className="input" />
                            </Form.Item>

                            <Form.Item
                                className="table-form-item mt-[1.75rem]"
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password',
                                    },
                                ]}
                            >
                                <Input.Password className="input" />
                            </Form.Item>

                            <div className="flex justify-end mt-[.5rem]">
                                <Link to="/forgot-password" className="label_2">
                                    Forgot Password?
                                </Link>
                            </div>

                            <div className="flex justify-center w-[10.625rem] mt-[1.63rem]">
                                <button className="cstm-btn-2" type="submit" disabled={authLoading ? true : false}>
                                    {authLoading ? <Spin /> : 'Log in'}
                                </button>
                            </div>

                            <div className="w-full flex mt-[2.94rem]">
                                <span className="label_2">Don’t have an account? </span>
                                <button className="label_2 !text-blu ml-[.1rem] underline" onClick={() => navigate('/registration')}>
                                    Register
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>

                <div className="hidden lg:flex w-full h-[100vh]">
                    <img src={loginImg} className="w-full h-full object-cover" alt="login" />
                </div>
            </div>
        </>
    );
}
