import { Tabs } from 'antd';
import { useEffect } from 'react';
import ContributionTypeDetailsCard from './cards/ContributionTypeDetailsCard';
import MemberManagementTab from './tabs/MemberManagementTab';
import ContributionsTypeStatementTab from './tabs/ContributionsTypeStatementTab';
import ContributionsTypeOverviewTab from './tabs/ContributionsTypeOverviewTab';

export default function ContributionTypeDetails() {
    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const items = [
        {
            key: '1',
            label: 'Overview',
            children: <ContributionsTypeOverviewTab />,
        },
        {
            key: '2',
            label: 'Members management',
            children: <MemberManagementTab />,
        },
        {
            key: '3',
            label: 'Statements',
            children: <ContributionsTypeStatementTab />,
            // disabled: true,
        },
    ];

    return (
        <>
            <ContributionTypeDetailsCard />

            <div className="flex flex-col w-full pt-[2.5rem] bg-white">
                <Tabs className="underlined_tab" items={items} />
            </div>
        </>
    );
}
