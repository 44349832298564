import { useNavigate } from 'react-router-dom';

export default function ActivitySummaryCard({
	header,
	amount,
	icon,
	footer,
	url,
	background,
	color,
}) {
	const navigate = useNavigate();

	return (
		<>
			<div
				onClick={url ? () => navigate(url) : null}
				style={{
					background: background,
					cursor: url ? 'pointer' : 'initial',
					color: color,
					border: '1px solid #E2E9EE',
				}}
				className='min-w-[15.1875rem] w-auto flex flex-col justify-center items-start flex-shrink-0 flex-grow px-[1.5rem] pt-[1.5rem] pb-[.69rem] min-h-[7rem] h-full rounded-[0.5rem]'>
				<div className='w-full flex items-start justify-between'>
					<span className='activity_card_header'>{header}</span>
					{icon}
				</div>
				<span
					style={{
						color: color,
					}}
					className='activity_card_text'>
					{amount}
				</span>
				<div className='w-full flex justify-start'>
					<span>{footer}</span>
				</div>
			</div>
		</>
	);
}
