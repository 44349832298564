import { Table } from 'antd';
import moment from 'moment';
import { formatMoney, getRandomColor } from '../../../utils';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const columns = [
	{
		title: 'Date',
		dataIndex: 'lastModifiedDate',
		render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
		sorter: (a, b) => a - b,
	},
	{
		title: 'Member',
		dataIndex: 'gpmName',
		render: (item) => (
			<>
				<div className='w-full flex items-center gap-[.5rem]'>
					<img
						src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item)}&background=${getRandomColor()}&color=fff`}
						className='w-[2.1875rem] h-[2.1875rem] rounded-full object-cover'
						alt='avatar'
					/>
					<span>{item}</span>
				</div>
			</>
		),
	},
	{
		title: 'Opening balance (KES)',
		dataIndex: 'gpmOpening',
		render: (item) => <span>{formatMoney(item, 'KES')}</span>,
	},
];

export default function MembersOpeningBalanceTable() {
	const { members } = useSelector((state) => state.fetch);

	useEffect(() => {}, [members]);

	return (
		<>
			<Table
				rowKey='trxId'
				className='mt-[1.5rem] !w-full'
				pagination={false}
				columns={columns}
				dataSource={members}
				scroll={{
                    x: 600,
                }}
			/>
		</>
	);
}
