import { Table } from 'antd';
import moment from 'moment';
import { applicationUrl, capitalize, capitalizeFirstLetter, formatMoney, getRandomColor } from '../../../utils';
import useFetch from '../../../custom_hooks/useFetch';
import { useEffect } from 'react';
import TableLoading from '../../../components/TableLoading';
import { statusColor } from '../../../data';

const columns = [
    {
        title: 'Date',
        dataIndex: 'withDrawCreatedDate',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
    },
    {
        title: 'Source account',
        dataIndex: 'withDrawCwCollection',
    },
    {
        title: 'Recipient',
        render: (item) => (
            <>
                <div className="w-full flex items-center gap-[.5rem]">
                    <img
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(item?.withDrawBankName)}&background=${getRandomColor()}&color=fff`}
                        className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                        alt="avatar"
                    />
                    <span>{item?.withDrawBankName}</span>
                </div>
            </>
        ),
    },
    {
        title: 'Recipient account',
        render: (item) => <span>{item?.withDrawAccountNumber}</span>,
    },
    {
        title: 'Status',
        dataIndex: 'withDrawStatus',
        render: (item) => {
            const roleColor = statusColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6'

            return (
                <div
                    style={{
                        background: roleColor,
                    }}
                    className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{capitalize(item?.split('_')?.join(' '))}</span>
                </div>
            );
        },
    },
    {
        title: 'Reason',
        dataIndex: 'withDrawParticular',
        render: (item) => <span>{capitalizeFirstLetter(item)}</span>,
    },
    {
        title: 'Amount',
        dataIndex: 'withDrawAmount',
        render: (item) => <span>{formatMoney(item, 'KES')}</span>,
    },
];

export default function BankDisbursementsTable() {
    const { isLoading, data } = useFetch(`${applicationUrl().url}/api/withdrawals/dynamic-withdraws?withDrawCategory=DISBURSEMENT&withDrawType=BANK`);

    useEffect(() => {}, [data]);

    if (isLoading) return <TableLoading />;

    return (
        <>
            <Table
                scroll={{
                    x: 1200,
                }}
                rowKey="withDrawId"
                className="mt-[1.5rem] !w-full"
                pagination={false}
                columns={columns}
                dataSource={data}
            />
        </>
    );
}
