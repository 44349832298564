import treePalm from "../../../assets/svg/TreePalm.svg"

export default function OnboardingCard({ color, background, content }) {
	return (
		<>
			<div
				style={{
					background: background,
				}}
				className='w-[10rem] max-w-full h-[11.8125rem] p-[1rem] rounded-[0.75rem] flex flex-col justify-center items-center'>
                    <img src={treePalm} alt="treePalm" />
                    <span className={`paragraph_1 ${color ? '' : '!text-white'} text-center mt-[1.25rem]`}>{content}</span>
                </div>
		</>
	);
}
