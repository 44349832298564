import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './layout/SideBar';
import SubHeader from './layout/SubHeader';
import axiosInstance from './instance';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { handleUser } from './features/auth/authSlice';
import InviteRequestModal from './components/InviteRequestModal';
import useModalToggle from './custom_hooks/useModalToggle';
import { fetchChamaInviteRequests, fetchNotifications } from './features/fetch/fetchSlice';

export const ProtectedRoute = () => {
    const { open, handleCancel, handleOpen } = useModalToggle();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { isActive } = useSelector((state) => state.auth);
    const { chamaInviteRequests } = useSelector((state) => state.fetch);

    const [newInviteObj, setnewInviteObj] = useState({});

    const token = localStorage.getItem('token');

    axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };

    async function handleInit() {
        if (token) {
            await handleDecodeToken();
        }
    }

    async function handlefetch() {
        if (token) {
            await dispatch(fetchNotifications());
            await dispatch(fetchChamaInviteRequests())
        }
    }

    async function handleDecodeToken() {
        try {
            let tokenObj = jwtDecode(token);
            await dispatch(handleUser(tokenObj));
        } catch (error) {
            await dispatch(handleUser({}));
        }
    }

    useEffect(() => {
        axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };
        handleInit();
    }, [token, isActive]);

    useEffect(() => {
        handlefetch();
    }, [pathname]);

    useEffect(() => {        
        if (chamaInviteRequests?.length > 0) {
            setnewInviteObj(chamaInviteRequests[0]);
            handleOpen();
        } else {
            setnewInviteObj({});
            handleCancel();
        }
    }, [chamaInviteRequests]);

    if (!isActive && !token) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="sticky top-0 z-[10]">
                    <SubHeader />
                </div>

                <div className="flex items-start justify-start w-full h-full pt-[1.5rem] pl-0 pr-[.75rem] lg:pl-[1rem] lg:pr-[4.88rem]">
                    <div className="flex h-full sticky top-0">
                        <SideBar />
                    </div>

                    <div className="pl-[.75rem] lg:pl-[2.5rem] w-full h-full">
                        <Outlet />
                    </div>
                </div>
            </div>

            <InviteRequestModal openMain={open} handleCancelMain={handleCancel} newInviteObj={newInviteObj} />
        </>
    );
};
