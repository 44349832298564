import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useFetchDispatch(func) {
	const dispatch = useDispatch();

	const [isLoading, setisLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await setisLoading(true);
			await dispatch(func());
			await setisLoading(false);
		};
		fetchData();
	}, [func]);
	return { isLoading };
}
