import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spin } from 'antd';
import AuthHeader from '../../layout/AuthHeader';
import DeclineInviteModal from './modals/DeclineInviteModal';
import { save } from '../../features/save/saveSlice';
import { applicationUrl, customToast, icon } from '../../utils';
import NewUserSuccessModal from './modals/NewUserSuccessModal';
import { fetchNewUserInviteObj, logout } from '../../features/auth/authSlice';


export default function NewUserInvite() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const [searchParams] = useSearchParams();

	const { newInviteObj } = useSelector((state) => state.auth);
	const { saving } = useSelector((state) => state.save);

	const token = searchParams.get('token');

	const [open, setopen] = useState(false);
	const [success, setsuccess] = useState(false);

	function handleCancel() {
		setopen(false);
		setsuccess(false);
	}

	async function handleInviteStatus() {
		if (newInviteObj?.mbiStatus === 'ACCEPT') {
			await customToast({
				content: 'You have already accepted this invite. Login to continue',
			});
            await dispatch(logout())
			await navigate('/login');
		} else if (newInviteObj?.mbiStatus === 'REJECT') {
			await customToast({
				content: 'This chama group invitation is no longer available.',
			});
            await dispatch(logout())
			await navigate('/login');
		}
	}

	async function handleFetch() {
		await dispatch(fetchNewUserInviteObj(token));
	}

	async function onFinish(data) {
		data.mbiInviteLink = token;
		data.mbiStatus = 'ACCEPT';
		data.url = applicationUrl().url;
		data.saveUrl = '/api/v1/reg/inviteAction';

		const res = await dispatch(save(data));        

		if (res?.payload?.success) {
		    setsuccess(true)
		} else {
			customToast({
				content: res?.payload?.messages?.message ?? 'An error occured',
				bdColor: 'error',
			});
		}
	}

	useEffect(() => {
		handleFetch();
	}, [token]);

	useEffect(() => {
		handleInviteStatus();
	}, [newInviteObj]);

	return (
		<>
			<AuthHeader />
			<div className='onboarding'>
				<div className='w-full flex justify-center items-center gap-[.94rem]'>
					<div
						style={{
							borderRadius: '0.875rem',
							background: '#FFF',
							boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
						className='w-[41rem] border border-blkDark'>
						<div className='flex justify-center items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
							<span className='heading_4 !text-white'>Chama invitation</span>
						</div>

						<div className='px-[2.94rem] py-[5rem]'>
							<div className='w-full flex flex-col  items-center'>
								{icon}
								<span className='heading_5 mt-[1.25rem]'>
									You’ve been invited to join {newInviteObj?.mbiCustName}
								</span>
								<span className='paragraph_2 text-center mt-[.625rem]'>
									Hi {newInviteObj?.mbiName}, you have received an invitation to
									join {newInviteObj?.mbiCustName}. <br />
									Accept or decline the invite below.
								</span>

								<Form
									layout='vertical'
									ref={formRef}
									name='newUserInvite'
									onFinish={onFinish}
									style={{
										maxWidth: '100%',
										marginTop: '3.75rem',
										width: '100%',
									}}
									form={form}>
									<div className='flex items-center justify-between mt-[5rem] w-full'>
										<div className='w-[8.25rem] flex'>
											<button
												disabled={saving}
												onClick={() => setopen(true)}
												className='decline_btn'
												type='button'>
												Decline
											</button>
										</div>

										<div className='w-[12.4375rem] flex items-center gap-[.5rem]'>
											<button
												disabled={saving}
												className='cstm-btn-2'
												type='submit'>
												{saving ? <Spin /> : 'Join'}
											</button>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<DeclineInviteModal open={open} handleCancel={handleCancel} item={newInviteObj} />
			<NewUserSuccessModal open={success} handleCancel={handleCancel} item={newInviteObj} />
		</>
	);
}
