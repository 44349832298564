import { useSelector } from 'react-redux';
import Search from '../../../components/Search';
import AvailableBalanceCard from '../../group/cards/AvailbaleBalanceCard';
import MemberRecentTransactionTable from '../tables/MemberRecentTransactionTable';
import { applicationUrl, capitalize, formatMoney, getFistName } from '../../../utils';
import useFetch from '../../../custom_hooks/useFetch';
import CardLoading from '../../../components/CardLoading';
import moment from 'moment';

export default function MemberOverview() {
	const { activeMember, activeChama } = useSelector((state) => state.obj);

	const { data, isLoading } = useFetch(
		`${applicationUrl().url}/api/v1/member/summary?memberNo=${
			activeMember?.gpmNumber
		}`
	);	

	const balanceObj = [
		{
			header: 'Total Contributions',
			amount: formatMoney(data?.totalSaving, 'KES'),
			background: 'rgba(147, 205, 147, 0.22)',
		},
		{
			header: 'Last contribution',
			amount: formatMoney(data?.lastContributionInfo?.lastContributionAmount, 'KES'),
			hasFooter: true,
			footer: data?.lastContributionInfo?.lastContributionDate ? moment(data?.lastContributionInfo?.lastContributionDate).format('Do MMMM YYYY') : 'N/A',
			background: '#fff',
		},
		// {
		// 	header: 'Active loans',
		// 	amount: formatMoney(data?.activeLoan, 'KES'),
		// 	background: '#fff',
		// },
		{
			header: 'Arrears',
			amount: formatMoney(data?.totalArrears, 'KES'),
			background: '#fff',
		},
		{
			header: 'Penalties',
			amount: formatMoney(data?.penalty, 'KES'),
			background: 'rgba(255, 228, 230, 0.69)',
		},
	];

	return (
		<>
			<div className='mt-[2.5rem] flex flex-col !pb-[5rem] p-[1.25rem] w-full'>
				<div className='flex items-center justify-between w-full'>
					<span className='heading_4'>
						Overview of {capitalize(getFistName(activeMember?.gpmName) + `'s`)}{' '}
						activity
					</span>
				</div>

				{isLoading ? (
					<CardLoading />
				) : (
					<>
						<div className='mt-[1.25rem] flex items-center overflow-x-auto overflow-y-hidden gap-[.75rem]'>
							{balanceObj.map((item, index) => {
								return (
									<div
										className='flex flex-grow flex-shrink-0 min-w-[15rem] w-auto'
										key={index}>
										<AvailableBalanceCard
											header={item.header}
											footer={item.footer}
											hasFooter={item.hasFooter}
											amount={item.amount}
											background={item.background}
										/>
									</div>
								);
							})}
						</div>
					</>
				)}

				<span className='heading_4 mt-[2.5rem]'>
					{capitalize(getFistName(activeMember?.gpmName) + `'s`)} recent
					transactions
				</span>
				<div className='flex items-center gap-[1rem] w-full mt-[1.25rem]'>
					<div className='w-[24.1875rem] '>
						<Search text={'Search ref no., activity or date'} />
					</div>
				</div>

				<MemberRecentTransactionTable />
			</div>
		</>
	);
}
