import { useState } from 'react';
import MakeContributionModal from '../pages/activities/modals/MakeContributionModal';

export default function InAppNotificationBar({
	message,
	header,
	color,
	showPay,
	item,
	type,
	amount
}) {
	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	return (
		<>
			<div
				style={{
					border: `1px solid #E2E9EE`,
					borderLeft: `.5rem solid ${color}`,
					borderRadius: '0.625rem',
				}}
				className='w-full flex items-center gap-[1.25rem] min-h-[4.125rem] h-auto py-[.88rem] px-[1.25rem]'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='28'
					height='28'
					viewBox='0 0 28 28'
					fill='none'>
					<path
						d='M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z'
						stroke={`${color}`}
						strokeWidth='2'
						strokeMiterlimit='10'
					/>
					<path
						d='M14 14.875V8.75'
						stroke={`${color}`}
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						d='M14 20.125C14.7249 20.125 15.3125 19.5374 15.3125 18.8125C15.3125 18.0876 14.7249 17.5 14 17.5C13.2751 17.5 12.6875 18.0876 12.6875 18.8125C12.6875 19.5374 13.2751 20.125 14 20.125Z'
						fill={`${color}`}
					/>
				</svg>
				<div className='flex w-full flex-col items-start'>
					<span className='heading_5'>{header}</span>
					<span className='paragraph_2'>{message}</span>
				</div>

				{false ? (
					<button
						onClick={() => setopen(true)}
						type='button'
						className='flex items-center gap-[.25rem]'>
						<span className='label_2'>Pay </span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M12 19.625L10.925 18.55L16.75 12.75H4.375V11.25H16.75L10.925 5.45L12 4.375L19.625 12L12 19.625Z'
								fill='black'
							/>
						</svg>
					</button>
				) : null}
			</div>
			<MakeContributionModal
				item={item}
				open={open}
				handleCancel={handleCancel}
				type={type}
				amount={amount}
			/>
		</>
	);
}
