import { CheckCircleOutlined } from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';
import { useState } from 'react';
import ReactPasswordChecklist from 'react-password-checklist';
import checkBox from '../../../assets/svg/Checkbox.svg';
import { useSelector } from 'react-redux';

export default function PasswordStep({ handleBack }) {
	const { authLoading } = useSelector((state) => state.auth);

	const [password, setpassword] = useState('');
	const [isValid, setisValid] = useState(false);

	return (
		<>
			<div className='flex flex-col w-full'>
				<Form.Item
					label='Password'
					name='usrPassword'
					rules={[
						{
							required: true,
							message: 'Please input your password',
						},
					]}>
					<Input.Password
						onChange={(e) => setpassword(e.target.value)}
						className='input'
						type='password'
					/>
				</Form.Item>

				<div className=''>
					<span className='paragraph_3 !text-[#616161]'>
						Password must contain:
					</span>
					<ReactPasswordChecklist
						className='text-darkBlue'
						rules={['minLength', 'capital', 'number']}
						minLength={8}
						value={password}
						onChange={(isValid) => {
							setisValid(isValid);
						}}
						messages={{
							minLength: 'A minimum of 8 characters',
							capital: 'At least one upper case letter (A-Z)',
							number: 'At least one number (0-9)',
						}}
						iconComponents={{
							ValidIcon: (
								<CheckCircleOutlined className='text-[1rem] mr-[.5rem] text-[green]' />
							),
							InvalidIcon: (
								<img className='mr-[.5rem]' src={checkBox} alt='checkBox' />
							),
						}}
					/>
				</div>

				<div className='py-[2.44rem] w-full flex justify-center items-center'>
					<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
						<button
							disabled={!isValid || authLoading}
							type='submit'
							className='cstm-btn-2'>
							{authLoading ? <Spin /> : 'Next'}
						</button>

						<button
							disabled={authLoading}
							onClick={handleBack}
							type='button'
							className='cstm-btn'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
