import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';
import AllAccountTransfersTable from '../tables/AllAccountTransfersTable';

export default function AllAccountTransfersTab() {
    return (
        <>
            <div className="grid grid-cols-1 gap-[1.25rem]">
                <div className="white_card flex-col">
                    <span className="heading_4 mt-[1rem]">All account transfers</span>
                    <div className="w-full grid grid-cols-1 gap-[1.25rem] mt-[1.94rem]">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center gap-[1rem] w-full">
                                <div className="w-[13.625rem] hidden lg:flex">
                                    <Search text={'Search'} />
                                </div>

                                <div className="w-[12.5rem] hidden lg:flex">
                                    <StatementFilter />
                                </div>
                            </div>
                        </div>

                        <AllAccountTransfersTable />
                    </div>
                </div>
            </div>
        </>
    );
}
