import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { customToast } from '../../../utils';
import {
	handleAuthData,
	resendOtpFotgot,
	resendRegOtp,
	verifyForgotOtp,
	verifyRegOtp,
} from '../../../features/auth/authSlice';

export default function RegisterOTPModal({
	open,
	handleCancel,
	handleNext,
	isForgot,
	usrId,
}) {
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const dispatch = useDispatch();

	const { authLoading, authData } = useSelector((state) => state.auth);

	const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

	const [verificationCode, setVerificationCode] = useState(['', '', '', '']);
	const [counter, setCounter] = useState({ minutes: 0, seconds: 0 });

	const cancatenatedString = verificationCode?.join('');

	const handleInputChange = (e, index) => {
		const { value } = e.target;

		if (value === '') {
			if (index > 0) {
				inputRefs[index - 1].current.focus();
			}
		} else if (index < inputRefs.length - 1) {
			inputRefs[index + 1].current.focus();
		}

		const updatedVerificationCode = [...verificationCode];
		updatedVerificationCode[index] = value;
		setVerificationCode(updatedVerificationCode);
	};

	const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

	const resendOTP = async () => {
		let res;

		if (isForgot) {
			res = await dispatch(
				resendOtpFotgot(usrId)
			);
		} else {
			res = await dispatch(
				resendRegOtp({
					usrId: authData?.usrId ?? usrId,
				})
			);
		}

		if (res?.payload?.success) {
			customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
				id: 997873
			});
			setCounter({ minutes: 0, seconds: 5 });
		} else {
			customToast({
				content:
					res?.payload?.messages?.message ??
					'Experiencing technical error. Please try again after some time',
				bdColor: 'error',
				id: 198354356
			});
		}
	};

	const onFinish = async () => {
		let res;

		if (isForgot) {
			res = await dispatch(
				verifyForgotOtp({
					usrId: usrId,
					secret: cancatenatedString,
				})
			);
		} else {
			res = await dispatch(
				verifyRegOtp({
					usrId: authData?.usrId,
					secret: cancatenatedString,
				})
			);
		}

		if (res?.payload?.success) {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
				id: 92386363
			});
			if (!isForgot) {
				await dispatch(handleAuthData(res?.payload?.data?.result));
			}
			await handleNext(res?.payload?.data?.result?.usrId);
			await handleCancel();
		} else {
			customToast({
				content:
					res?.payload?.messages?.message ??
					'Experiencing technical error. Please try again after some time',
				bdColor: 'error',
				id: 1983663
			});
		}
	};

	async function handleClose() {
		await form.resetFields();
		await handleCancel();
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (counter.minutes === 0 && counter.seconds === 0) {
				clearInterval(interval);
			} else {
				setCounter((prevCounter) => {
					if (prevCounter.seconds === 0) {
						return { minutes: prevCounter.minutes - 1, seconds: 5 };
					} else {
						return { ...prevCounter, seconds: prevCounter.seconds - 1 };
					}
				});
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [counter]);

	return (
		<>
			<Modal
				afterOpenChange={(isOpen) =>
					isOpen
						? setCounter({ minutes: 0, seconds: 5 })
						: setCounter({ minutes: 0, seconds: 0 })
				}
				centered
				className='custom-modal'
				title='Chama group code'
				open={open}
				onCancel={handleClose}
				footer={false}>
				<div className='flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
					<span className='heading_4 !text-white'>OTP Verification</span>
					<button onClick={handleClose}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='white'
							/>
						</svg>
					</button>
				</div>

				<div className='px-[2.94rem] pt-[5rem]'>
					<div className='w-full flex flex-col justify-center items-center'>
						<span className='heading_5'>Let’s verify your details</span>
						<span className='paragraph_2 mt-[1.25rem]'>
							Enter the 4 digit code sent to your email
						</span>

						<Form
							layout='vertical'
							ref={formRef}
							name='otpModal'
							onFinish={onFinish}
							style={{
								maxWidth: '100%',
								marginTop: '3.75rem',
							}}
							form={form}>
							<div className='flex flex-col'>
								<div className='flex items-center gap-[1.25rem]'>
									{inputRefs?.map((ref, index) => (
										<div key={index}>
											<input
												ref={ref}
												className='otp-input'
												type='text'
												maxLength='1'
												value={verificationCode[index]}
												onChange={(e) => handleInputChange(e, index)}
												onKeyDown={(e) => handleKeyDown(e, index)}
											/>
										</div>
									))}
								</div>
							</div>

							<div className='mt-[2rem] flex justify-center w-full'>
								{counter.minutes === 0 && counter.seconds === 0 ? (
									<button
										className='label_3 underline'
										onClick={resendOTP}
										type='button'>
										Resend code?
									</button>
								) : (
									<span className='label_3 !text-blu'>
										{' '}
										Resend in {counter.minutes.toString().padStart(2, '0')}:
										{counter.seconds.toString().padStart(2, '0')}
									</span>
								)}
							</div>

							<div className='flex items-center justify-center mt-[5rem] w-full'>
								<div className='w-[12.4375rem] flex flex-col gap-[.5rem]'>
									<button
										disabled={cancatenatedString.length !== 4 || authLoading}
										className='cstm-btn-2'
										type='submit'>
										{authLoading ? <Spin /> : 'Next'}
									</button>

									<button
										disabled={authLoading}
										onClick={handleClose}
										className='cstm-btn'
										type='button'>
										Cancel
									</button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	);
}
