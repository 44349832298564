import { Modal } from 'antd';
import { logout } from '../../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function NewUserSuccessModal({ open, handleCancel, item }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	async function handleClose() {
		await dispatch(logout());
		await handleCancel();
		await navigate('/login');
	}

	return (
		<>
			<Modal
				centered
				className='custom-modal'
				title='Success'
				open={open}
				footer={false}>
				<div className='flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
					<span className='heading_4 !text-white'>Success</span>
				</div>

				<div className='px-[2.94rem] mt-[2.56rem]'>
					<div className='w-full flex flex-col'>
						<div
							style={{
								borderRadius: '0.5rem',
								border: '1px solid #3BBB6E',
								background: '#FFF',
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
							}}
							className='w-full flex items-start gap-[.5rem] p-[1rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986'
									stroke='#3BBB6E'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M22 4L12 14.01L9 11.01'
									stroke='#3BBB6E'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>

							<div className='flex flex-col gap-[.5rem]'>
								<span className='modal_success_header'>
									You are now a member of {item?.mbiCustName} chama group
								</span>
								{item?.mbiUsrId === null ? (
									<span className='modal_success_text'>
										We have sent login credentials to your email and phone
										number. Login at{' '}
										<a
											className='text-blu underline'
											href='https://chamify.africa'>
											Chamify.africa
										</a>{' '}
										to view chama group activities
									</span>
								) : (
									<span className='modal_success_text'>
										Login at{' '}
										<a
											className='text-blu underline'
											href='https://chamify.africa'>
											Chamify.africa
										</a>{' '}
										to view your new chama group activities
									</span>
								)}
							</div>
						</div>

						<div className='flex items-center justify-center mt-[5rem] w-full'>
							<div className='w-[9.1875rem] flex items-center gap-[.5rem]'>
								<button
									onClick={handleClose}
									className='cstm-btn-2'
									type='button'>
									Okay
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}
