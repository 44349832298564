import { useEffect, useState } from 'react';
import {
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Select,
	Spin,
	Tooltip,
} from 'antd';
import info from '../../../assets/svg/Info.svg';
import info32 from '../../../assets/svg/Info32.svg';
import { booleanType } from '../../../data';

export default function LoanSettingStep({ handleNext, form, handleBack }) {
	const [add, setadd] = useState(0);
	const [roles, setroles] = useState([]);
	const [roleValue, setroleValue] = useState('');

	function handleActiveStep(item) {
		setadd(item);
	}

	async function handleSaveRole() {
		try {
			await form.validateFields(['roleName', 'rolePermissions']);
			let data = form.getFieldsValue();
			await setroles([...roles, roleValue]);
			await setadd(false);
		} catch {}
	}

	useEffect(() => {
		window.scrollTo({
			left: 0,
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<>
			<div className='flex flex-col w-full'>
				<Form.Item
					label='Loan interest rate (%)'
					name='lnInterest'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<InputNumber className='input' />
				</Form.Item>

				<Form.Item
					label='Minimum loan tenure'
					name='lnMinTenure'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Input
						suffix={<span className='paragraph_3 !text-[#616161]'>months</span>}
						className='input'
					/>
				</Form.Item>

				<Form.Item
					label='Maximum loan tenure'
					name='lnMaxTenure'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Input
						suffix={<span className='paragraph_3 !text-[#616161]'>months</span>}
						className='input'
					/>
				</Form.Item>

				<Form.Item
					label='Minimum amount one can borrow against contribution'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Radio.Group className='flex items-center gap-[1rem]' value={null}>
						<Form.Item
							label='Percentage (%)'
							name='lnMinBorrowAmountPercentage'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Input prefix={<Radio value={true}></Radio>} className='input' />
						</Form.Item>

						<span className='label_2'>Or</span>

						<Form.Item
							label='Fixed amount (KES)'
							name='lnMinBorrowAmountFixed'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Input prefix={<Radio value={true}></Radio>} className='input' />
						</Form.Item>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					label='Amount at which one requires guarantor to borrow'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Radio.Group className='flex items-center gap-[1rem]' value={null}>
						<Form.Item
							label='Percentage (%)'
							name='lnAmountRequireGuarantorPercentage'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Input prefix={<Radio value={true}></Radio>} className='input' />
						</Form.Item>

						<span className='label_2'>Or</span>

						<Form.Item
							label='Fixed amount (KES)'
							name='lnAmountRequireGuarantorFixed'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Input prefix={<Radio value={true}></Radio>} className='input' />
						</Form.Item>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					label='Enable guarantor assistance'
					name={'lnGuarantorAssistance'}
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<Select
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='21'
								viewBox='0 0 21 21'
								fill='none'>
								<path
									d='M17.1922 8.21268L10.9422 14.4627C10.8841 14.5208 10.8152 14.5669 10.7393 14.5983C10.6634 14.6298 10.5821 14.646 10.5 14.646C10.4178 14.646 10.3365 14.6298 10.2606 14.5983C10.1848 14.5669 10.1158 14.5208 10.0578 14.4627L3.80779 8.21268C3.69052 8.0954 3.62463 7.93634 3.62463 7.77049C3.62463 7.60464 3.69052 7.44558 3.80779 7.3283C3.92507 7.21103 4.08413 7.14514 4.24998 7.14514C4.41583 7.14514 4.57489 7.21103 4.69217 7.3283L10.5 13.1369L16.3078 7.3283C16.3659 7.27023 16.4348 7.22417 16.5107 7.19274C16.5865 7.16132 16.6679 7.14514 16.75 7.14514C16.8321 7.14514 16.9134 7.16132 16.9893 7.19274C17.0652 7.22417 17.1341 7.27023 17.1922 7.3283C17.2502 7.38637 17.2963 7.45531 17.3277 7.53118C17.3592 7.60705 17.3753 7.68837 17.3753 7.77049C17.3753 7.85261 17.3592 7.93393 17.3277 8.0098C17.2963 8.08567 17.2502 8.15461 17.1922 8.21268Z'
									fill='#212121'
								/>
							</svg>
						}
						options={booleanType}
					/>
				</Form.Item>

                <Form.Item
					label='Maximum percentage a member can guarantee'
					name='lnMaxGuarantorPercentage'
					rules={[
						{
							required: false,
							message: 'Field is required',
						},
					]}>
					<InputNumber className='input' />
				</Form.Item>

				<div className='py-[2.37rem] w-full flex justify-center items-center'>
					<div className='w-[10.625rem] flex flex-col gap-[.5rem]'>
						<button
							// disabled={saving}
							onClick={() => handleNext()}
							type='button'
							className='cstm-btn-2'>
							Next
						</button>

						<button
							onClick={() => handleBack()}
							type='button'
							className='cstm-btn'>
							Previous
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
