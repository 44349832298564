import React, { useRef } from 'react';
import { DatePicker, Form, Modal, Spin } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { previewReportSummary } from '../utils';
import moment from 'moment';

export default function ExportToPDFSummaryModal({ open, handleCancel, P_STATEMENT, rptId, P_USER }) {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { activeChama } = useSelector((state) => state.obj);

	const onFinish = async (data) => {        
        data.P_CUST_ID = activeChama?.custId
        data.P_START_DATE = moment(data.P_START_DATE?.$d).format('YYYY-MM-DD')
        data.P_END_DATE = moment(data.P_END_DATE?.$d).format('YYYY-MM-DD')
        data.P_STATEMENT = P_STATEMENT
		data.rptId = rptId
		data.P_USER = P_USER
        previewReportSummary(data)
        
	};

	async function handleClose() {
		await form.resetFields();
		await handleCancel();
	}

	return (
		<>
			<Modal
				centered
				className='custom-modal'
				title='Export to pdf'
				open={open}
				onCancel={handleClose}
				footer={false}>
				<div className='flex justify-between items-center bg-[#10101E] w-full h-[4.375rem] px-[2.3125rem]'>
					<span className='heading_4 !text-white'>Export to PDF</span>
					<button onClick={handleClose}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='white'
							/>
						</svg>
					</button>
				</div>

				<div className='px-[2.94rem] pt-[2rem]'>
					<div className='w-full flex flex-col justify-center items-center'>
						<Form
							layout='vertical'
							ref={formRef}
							name='otpModal'
							onFinish={onFinish}
							style={{
								maxWidth: '100%',
								marginTop: '3.75rem',
                                width: '100%'
							}}
							form={form}>
							<Form.Item
								label='From'
								name='P_START_DATE'
								rules={[
									{
										required: true,
										message: 'Date is required',
									},
								]}>
								<DatePicker
									minDate={dayjs(activeChama?.createdDate)}
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='22'
											height='23'
											viewBox='0 0 22 23'
											fill='none'>
											<path
												d='M17.875 3.52881H4.125C3.7453 3.52881 3.4375 3.83661 3.4375 4.21631V17.9663C3.4375 18.346 3.7453 18.6538 4.125 18.6538H17.875C18.2547 18.6538 18.5625 18.346 18.5625 17.9663V4.21631C18.5625 3.83661 18.2547 3.52881 17.875 3.52881Z'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M15.125 2.15381V4.90381'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M6.875 2.15381V4.90381'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M3.4375 7.65381H18.5625'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									}
									className='input'
									placeholder='Start date'
								/>
							</Form.Item>

                            <Form.Item
								label='To'
								name='P_END_DATE'
								rules={[
									{
										required: true,
										message: 'Date is required',
									},
								]}>
								<DatePicker
									maxDate={dayjs(new Date())}
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='22'
											height='23'
											viewBox='0 0 22 23'
											fill='none'>
											<path
												d='M17.875 3.52881H4.125C3.7453 3.52881 3.4375 3.83661 3.4375 4.21631V17.9663C3.4375 18.346 3.7453 18.6538 4.125 18.6538H17.875C18.2547 18.6538 18.5625 18.346 18.5625 17.9663V4.21631C18.5625 3.83661 18.2547 3.52881 17.875 3.52881Z'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M15.125 2.15381V4.90381'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M6.875 2.15381V4.90381'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M3.4375 7.65381H18.5625'
												stroke='#343330'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									}
									className='input'
									placeholder='End date'
								/>
							</Form.Item>
							<div className='flex items-center justify-center mt-[5rem] w-full'>
								<div className='w-[12.4375rem] flex flex-col gap-[.5rem]'>
									<button className='cstm-btn-2' type='submit'>
										{false ? <Spin /> : 'Generate report'}
									</button>

									<button
										onClick={handleClose}
										className='cstm-btn'
										type='button'>
										Cancel
									</button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	);
}
