import { DatePicker, Form, Input, Spin } from 'antd';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import RegisterOTPModal from '../modals/RegisterOTPModal';
import FileUploader from '../../../components/FileUploader';
import { useDispatch, useSelector } from 'react-redux';
import { handleAuthData, register } from '../../../features/auth/authSlice';
import { customToast } from '../../../utils';
import dayjs from 'dayjs';

export default function PersonalDetailsStep({ handleNext, form }) {
	const dispatch = useDispatch();

	const { authLoading, authData } = useSelector((state) => state.auth);

	const [open, setopen] = useState(false);
	const [kraFile, setkraFile] = useState(authData?.usrKraPinFile ?? '');

	const today = new Date();
	let dd = String(today.getDate()).padStart(2, 0);
	let mm = String(today.getMonth() + 1).padStart(2, 0);
	let yyyy = today.getFullYear() - 18;

	let minDate = `${yyyy}-${mm}-${dd}`;

	function handleCancel() {
		setopen(false);
	}

	async function handleSubmitStep() {
		try {
			await form.validateFields([
				'usrFirstname',
				'usrLastname',
				'usrMobileNumber',
				'usrDob',
			]);
			let personalData = form.getFieldsValue();

			personalData.usrKraPinFile = kraFile;
			personalData.usrId = authData?.usrId;

			const res = await dispatch(register(personalData));
			console.log(res);

			if (res?.payload?.success) {				
				await dispatch(handleAuthData(res?.payload?.data?.result));
				await setopen(true);
			} else {
				customToast({
					content: res?.payload?.messages?.message,
					bdColor: 'error',
					id: 554446765
				});
			}
		} catch {}
	}

	const handleSuccess = (response) => {
		setkraFile(response?.data?.result);
	};

	return (
		<>
			<div className='flex flex-col w-full'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
					<Form.Item
						label='First name'
						name='usrFirstname'
						rules={[
							{
								required: true,
								message: 'First name is required',
							},
							{
								pattern: /^[a-zA-Z0-9_]+$/,
								message: 'Only first name is required',
							},
						]}>
						<Input className='input' />
					</Form.Item>

					<Form.Item
						label='Last name'
						name='usrLastname'
						rules={[
							{
								required: true,
								message: 'Last name is required',
							},
							{
								pattern: /^[a-zA-Z0-9_]+$/,
								message: 'Only last name is required',
							},
						]}>
						<Input className='input' />
					</Form.Item>
				</div>

				<Form.Item
					label='Date of birth'
					name='usrDob'
					rules={[
						{
							required: true,
							message: 'Date of birth is required',
						},
					]}>
					<DatePicker
						maxDate={dayjs(minDate)}
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='22'
								height='23'
								viewBox='0 0 22 23'
								fill='none'>
								<path
									d='M17.875 3.52881H4.125C3.7453 3.52881 3.4375 3.83661 3.4375 4.21631V17.9663C3.4375 18.346 3.7453 18.6538 4.125 18.6538H17.875C18.2547 18.6538 18.5625 18.346 18.5625 17.9663V4.21631C18.5625 3.83661 18.2547 3.52881 17.875 3.52881Z'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M15.125 2.15381V4.90381'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M6.875 2.15381V4.90381'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M3.4375 7.65381H18.5625'
									stroke='#343330'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						}
						className='input'
						placeholder=''
					/>
				</Form.Item>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
					<Form.Item label='KRA PIN' name='usrKraPin'>
						<Input className='input' />
					</Form.Item>

					<Form.Item label='Upload KRA PIN'>
						<FileUploader
							fileName={kraFile?.split('_').pop()}
							name='usrKraPinFile'
							onSuccess={handleSuccess}
						/>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
					<Form.Item
						label='Email'
						name='usrEmail'
						rules={[
							{
								required: true,
								message: 'Please input your email',
							},
						]}>
						<Input className='input' type='email' />
					</Form.Item>

					<Form.Item
						label='Phone number'
						name='usrMobileNumber'
						validateFirst
						rules={[
							{
								required: true,
								message: 'Phone number is required',
							},
							{
								validator: (_, value) => {									
									if (value && value.length >= 12 && value.length <= 13) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error(
											'Phone number must be exactly 10 characters excluding country code'
										)
									);
								},
							},
						]}>
						<PhoneInput
							enableSearch
							country='ke'
							countryCodeEditable={false}
							className='input'
						/>
					</Form.Item>
				</div>

				<div className='py-7 w-full flex justify-center'>
					<button
						disabled={authLoading}
						onClick={handleSubmitStep}
						type='button'
						className='cstm-btn-2 !w-[200px]'>
						{authLoading ? <Spin /> : 'Next'}
					</button>
				</div>
			</div>

			<RegisterOTPModal
				handleNext={handleNext}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
