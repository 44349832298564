import { Table } from 'antd';
import { capitalize, formatMoney, getRandomColor } from '../../../utils';
import moment from 'moment';
import TableLoading from '../../../components/TableLoading';
import useFetchDispatch from '../../../custom_hooks/useFetchDispatch';
import { fetchManualDebitNotes } from '../../../features/money/moneySlice';
import { useSelector } from 'react-redux';
import { debitNoteTypeColor, statusColor } from '../../../data';

export default function DebitNotesTable() {
    const { isLoading } = useFetchDispatch(fetchManualDebitNotes);

    const { manualDebitNotes } = useSelector((state) => state.money);
    const { user } = useSelector((state) => state.auth);

    if (isLoading) return <TableLoading />;

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            render: (item) => <span>{moment(item).format('Do MMMM YYYY')}</span>,
        },
        {
            title: 'Particulars',
            dataIndex: 'schParticular',
        },
        {
            title: 'Type',
            dataIndex: 'schSourceType',
            render: (item) => {
                const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6'

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item)}</span>
                    </div>
                );
            },
        },
        {
            title: 'Member',
            dataIndex: 'groupMember',
            render: (item) => (
                <>
                    <div className="w-full flex items-center gap-[.5rem]">
                        <img
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user?.userName)}&background=${getRandomColor()}&color=fff`}
                            className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
                            alt="avatar"
                        />
                        <span>{item?.gpmName}</span>
                    </div>
                </>
            ),
        },
        {
            title: 'Amount (KES)',
            dataIndex: 'schContAmount',
            render: (item) => {
                return <span>{formatMoney(Math.abs(item), 'KES')}</span>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'schStatus',
            render: (item) => {
                const roleColor = statusColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6'

                return (
                    <div
                        style={{
                            background: roleColor,
                        }}
                        className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                            <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                        </svg>
                        <span>{capitalize(item)}</span>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Table
                rowKey="schId"
                className="mt-[1.5rem] !w-full"
                pagination={{
                    defaultPageSize: 7,
                    hideOnSinglePage: true,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                columns={columns}
                scroll={{
                    x: 1200,
                }}
                dataSource={manualDebitNotes}
            />
        </>
    );
}
